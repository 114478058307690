import React, { useEffect, useState } from 'react';
import AnnouncesListItemTag from '../../components/AnnouncesListItemTag';
import AnouncesTagsModal from '../../components/AnouncesTagsModal';
import FilterListHeader from '../../components/FilterListHeader';
import FilterListTags from '../../components/FilterListTags/FilterListTags';
import FixedActions from '../../components/FixedActions/FixedActions';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import Pagination from '../../components/Pagination';
import SideNav from '../../components/SideNav';
import Spinner from '../../components/Spinner';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getLabels } from '../../redux/labels/labelsThunk';
import { haveUserPermission } from '../../helpers/useHelper';
import { Permissions } from '../../models/Permissions';
import './AnnouncesTags.scss';

interface Props {}

const AnnouncesTags: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { labels, count, loadingLabels } = useAppSelector(
    state => state.labels,
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [havePermission, setHavePermission] = useState(
    haveUserPermission(Permissions.AdTagList),
  );
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(24);
  const [prevState, setPrevState] = useState({
    page: 0,
    pageSize: 24,
  });
  const [filtersForm, setFiltersForm] = useState<any>({});

  useEffect(() => {
    let newState = {
      page: page,
      pageSize: pageSize,
    };

    if (
      JSON.stringify(prevState.page) !== JSON.stringify(newState.page) ||
      JSON.stringify(prevState.pageSize) !== JSON.stringify(newState.pageSize)
    ) {
      getList();
      setPrevState(newState);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize]);

  useEffect(() => {
    if (page === 0) {
      getList();
    } else {
      setPage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersForm]);

  const getList = () => {
    dispatch(
      getLabels({
        limit: pageSize,
        offset: page + 1,
        withAnnouncements: 1,
      }),
    );
  };

  const ModalAction = () => {
    setIsModalOpen(true);
    setIsAction(true);
    setIsSearch(false);
    setIsFilter(false);
  };

  const ModalFilter = () => {
    setIsModalOpen(true);
    setIsFilter(true);
    setIsAction(false);
    setIsSearch(false);
  };

  const ModalSearch = () => {
    setIsModalOpen(true);
    setIsSearch(true);
    setIsFilter(false);
    setIsAction(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    setPage(selectedItem.selected);
  };

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        <Header
          title='Etichete ImoAP'
          subtitle='Lista Etichetelor adaugate in sectiunea de ImoAP'
          isbutton={false}
          btnText='Adauga Eticheta'
        />
        {havePermission ? (
          <>
            <FilterListHeader
              pageCount={Math.ceil(count / pageSize)}
              currentPage={page}
              itemsPerPage={pageSize}
              totalCount={count}
              count={labels?.length}
              setItemsPerPage={e => setPageSize(e)}
              handlePageClick={handlePageClick}
              showActions={false}
            />
            <FilterListTags />
            {loadingLabels ? (
              <div className='container'>
                <Spinner />
              </div>
            ) : (
              <>
                <div className='list-items-container'>
                  {labels.map(label => (
                    <AnnouncesListItemTag
                      key={label.id + 'checked'}
                      label={label}
                      onRemovedLabel={getList}
                    />
                  ))}
                </div>
                <Pagination
                  isSelectPage={false}
                  currentPage={page}
                  itemsPerPage={pageSize}
                  pageCount={Math.ceil(count / pageSize)}
                  handlePageClick={handlePageClick}
                  setItemsPerPage={e => setPageSize(e)}
                />
              </>
            )}

            <FixedActions
              disabledActions={labels.every(label => !label.checked)}
              ModalAction={ModalAction}
              ModalFilter={ModalFilter}
              ModalSearch={ModalSearch}
            />
            <AnouncesTagsModal
              isModalOpen={isModalOpen}
              closeModal={closeModal}
              isAction={isAction}
              isSearch={isSearch}
              isFilter={isFilter}
            />
          </>
        ) : (
          <MissingAccess />
        )}
      </div>
    </div>
  );
};

export default AnnouncesTags;

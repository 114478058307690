import React from 'react';
import './FilterListTags.scss';

interface Props {
  closeModal?: () => void;
}

const FilterListTags: React.FC<Props> = ({ closeModal }) => {
  return (
    <div className='filter-list-container filter-list-tags'>
      <div className='filter-item'>
        <label>Id</label>
      </div>
      <div className='filter-item'>
        <label>Titlu</label>
      </div>
      <div className='filter-item'>
        <label>Anunturi</label>
      </div>
      <div className='filter-item'>
        <label>Data Adaugare</label>
      </div>
      <div className='filter-item'>
        <label>Actiuni</label>
      </div>
      <div className='bottom-filter-section'>
        <div className='form-btns-section'>
          <button
            className='reset-btn'
            onClick={e => {
              e.preventDefault();
              closeModal && closeModal();
            }}>
            Reseteaza
          </button>
          <button
            className='filter-btn btn-blue'
            onClick={e => {
              e.preventDefault();
              closeModal && closeModal();
            }}>
            Filtreaza
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterListTags;

import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import { toast } from 'react-toastify';
import { setLabels } from '../../redux/announces/announcesThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  checkAll,
  clearFoundLabels,
  resetAnnouncementsModalLabels,
  setAssociatedLabels,
  setSearchQuery,
  toggleAnnouncementsModalLabels,
} from '../../redux/labels/labels-slice';
import { addLabel, searchLabels } from '../../redux/labels/labelsThunk';
import useDebounce from '../../helpers/useDebounce';
import { parseValues } from '../../helpers/useHelper';
import { Announce } from '../../models/Announces';
import { Label } from '../../models/Labels';
import IconTimes from '../../icons/IconTimes';
import FormSelect from '../FormSelect';
import './ModalAdLabels.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  announce?: Announce;
  showModal: boolean;
  handleClose: () => void;
  onSaved?: () => void;
}

const ModalAdLabels: React.FC<Props> = ({ announce, showModal, handleClose, onSaved }) => {
  const dispatch = useAppDispatch();

  const { recommendedLabels, popularLabels, associatedLabels, foundLabels } = useAppSelector(state => state.labels);

  const [labelsQuery, setLabelsQuery] = useState<string | undefined>(undefined);

  const debouncedLabelSearch = useDebounce(labelsQuery ?? null, 300);

  useEffect(() => {
    if (announce) {
      dispatch(setAssociatedLabels(announce.labels));
    }
    // eslint-disable-next-line
  }, [announce]);

  useEffect(() => {
    debouncedLabelSearch &&
      dispatch(setSearchQuery(debouncedLabelSearch)) &&
      dispatch(searchLabels(debouncedLabelSearch));
    // eslint-disable-next-line
  }, [debouncedLabelSearch]);

  const saveLabels = async () => {
    if (announce) {
      await dispatch(
        setLabels({
          announcementId: announce.id,
          labelsIds: Array.from(
            new Set<number>([
              ...recommendedLabels.filter(label => label.checked).map(label => label.id),
              ...popularLabels.filter(label => label.checked).map(label => label.id),
              ...associatedLabels.map(label => label.id),
            ]),
          ),
        }),
      ).then(processResponse);
    }
  };

  const processResponse = (response: any) => {
    if (response.error) {
      toast.error(
        `Eroare la setarea etichetelor ${typeof response.payload === 'string' ? ': ' + response.payload : ''}`,
      );
    } else {
      toast.success(`Etichetele au fost setate cu succes.`);
      closeModal();
      onSaved && onSaved();
    }
  };

  const closeModal = () => {
    dispatch(checkAll(false));
    dispatch(resetAnnouncementsModalLabels());
    handleClose();
  };

  function handleCheckboxChange(id: number) {
    if (id) {
      dispatch(toggleAnnouncementsModalLabels(id));
    }
  }

  const handleOnChange = async (e: any) => {
    if (e.value === '-1') {
      addNewLabel();
      return;
    }

    if (!associatedLabels.some(al => al.id.toString() === e.value)) {
      dispatch(setAssociatedLabels([...associatedLabels, foundLabels.find(label => label.id.toString() === e.value)]));
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      const firstLabel = foundLabels.at(0);

      if (firstLabel?.id === -1) {
        addNewLabel();
      } else {
        setAssociatedLabels([...associatedLabels, firstLabel]);
      }
    }
  };

  const addNewLabel = () => {
    dispatch(addLabel({ title: debouncedLabelSearch })).then(response => {
      if (response?.payload?.id) {
        toast.success(`Eticheta a fost adaugata cu succes.`);
        dispatch(setAssociatedLabels([...associatedLabels, response.payload]));
      } else {
        toast.error(
          `Eroare la adaugarea etichetei ${typeof response.payload === 'string' ? ': ' + response.payload : ''}`,
        );
      }
    });
  };

  return (
    <>
      <Modal show={showModal} onHide={closeModal} animation={false} className='labels-modal-center'>
        <>
          <button className='close-modal' onClick={closeModal} aria-label='Închide'>
            <IconTimes />
          </button>

          <div className='modal-container'>
            <Modal.Header>
              <Modal.Title>Eticheta</Modal.Title>
            </Modal.Header>
            <div className='modal-subtitle'>Seteaza etichete pentru acest anunt</div>
            <Modal.Body>
              <Container>
                <Row>
                  <div className='form-item'>
                    <h5 className='heading'>Etichete recomandate:</h5>
                    <ul className='associated-labels-list'>
                      {recommendedLabels &&
                        recommendedLabels.length > 0 &&
                        recommendedLabels?.map(item => {
                          return (
                            <li className='associated-labels-list__item' key={item.id}>
                              <Form.Check
                                className='label-item-rounded-info-sm'
                                type='checkbox'
                                label={item.title}
                                checked={item.checked}
                                readOnly
                                onClick={() => handleCheckboxChange(item.id)}
                              />
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                  <div className='form-item'>
                    <h5 className='heading'>Cele mai folosite 10 etichete:</h5>
                    <ul className='associated-labels-list'>
                      {popularLabels &&
                        popularLabels.length > 0 &&
                        popularLabels?.map(item => {
                          return (
                            <li className='associated-labels-list__item' key={item.id}>
                              <Form.Check
                                className='label-item-rounded-info-sm'
                                type='checkbox'
                                label={item.title}
                                checked={item.checked}
                                readOnly
                                onClick={() => handleCheckboxChange(item.id)}
                              />
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                  <div className='form-item'>
                    <h5 className='heading'>Eticheta:</h5>
                    <FormSelect
                      type='text'
                      placeholder=' '
                      id='ad-labels-query'
                      isSearchable={true}
                      className='rotate-arrow'
                      inlineLabel='Adauga eticheta'
                      labelText={true}
                      options={parseValues(foundLabels)}
                      onInputChange={(e: string) => {
                        setLabelsQuery(e);
                      }}
                      clearOnSelect={true}
                      onChange={handleOnChange}
                      handleKeyDown={handleKeyDown}
                      onMenuClose={() => {
                        dispatch(clearFoundLabels());
                      }}
                    />
                    <div className='add-block'>
                      {associatedLabels.map((item: Label) => {
                        return (
                          <div className='quantity' key={'label' + item.id}>
                            <span className=' label-item-rounded-info-sm'>{item?.title}</span>
                            <span
                              className='close'
                              onClick={() => {
                                dispatch(setAssociatedLabels(associatedLabels.filter(label => label.id !== item.id)));
                              }}>
                              <IconTimes />
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className='buttons'>
                    <div className='form-item-button'>
                      <button className='reset-btn button-md' onClick={closeModal}>
                        Inchide
                      </button>
                    </div>
                    <div className='form-item-button'>
                      <button className='button-blue button-md' onClick={saveLabels}>
                        Trimite
                      </button>
                    </div>
                  </div>
                </Row>
              </Container>
            </Modal.Body>
          </div>
        </>
      </Modal>
    </>
  );
};

export default ModalAdLabels;

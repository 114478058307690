import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { getFilePath } from '../../helpers/useHelper';
import { Contact } from '../../models/Contacts';
import Avatar from '../../assets/img/avatar.png';
import IconAddition from '../../icons/IconAddition';
import IconEnvelope from '../../icons/IconEnvelope';
import IconPencil from '../../icons/IconPencil';
import IconPhone from '../../icons/IconPhone';
import IconTrash from '../../icons/IconTrash';
import ModalConfirm from '../ModalConfirm';
import './ContactsListItem.scss';

interface Props {
  item: Contact;
  checked: boolean;
  handleCheckboxChange: (id: number) => void;
  removeItem: (id: number) => void;
}

const ContactsListItem: React.FC<Props> = ({
  item,
  checked,
  handleCheckboxChange,
  removeItem,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    item && (
      <div
        className={`announces-list-item contact-item ${checked ? 'active' : ''}`}
      >
        <div className='mobile-lines'>
          <div className='line-border'></div>
          <div className='checkbox-container'>
            <Form.Group id='formGridCheckbox'>
              <Form.Check
                type='checkbox'
                checked={checked}
                readOnly
                onClick={() => handleCheckboxChange(item.id)}
              />
            </Form.Group>
          </div>
          <div className='line-border'></div>
        </div>
        <div className='list-item-content'>
          <div className='column-item'>
            {item.avatarPath ? (
              <div className='info-img'>
                <img src={getFilePath(item.avatarPath)} alt='' />
              </div>
            ) : (
              <div className='info-img'>
                <img src={Avatar} alt='ProfileIcon' />
              </div>
            )}
            <div className='info-contact-details'>
              <div className='name'>{item.name + ' ' + item.surname}</div>
              <div className='phone-numbers'>
                {item.phoneNumbers.map(
                  phone =>
                    phone.phone && (
                      <a href={'tel:' + phone.phone} aria-label=''>
                        <IconPhone />
                        {phone.phone}
                      </a>
                    ),
                )}
                {item.internationalPhoneNumber && (
                  <a
                    href={'tel:' + item.internationalPhoneNumber}
                    aria-label=''
                  >
                    <IconPhone />
                    {item.internationalPhoneNumber}
                  </a>
                )}
              </div>
              <div className='mail'>
                {item.emails.map(
                  email =>
                    email && (
                      <a href={'mailto:' + email} aria-label=''>
                        <IconEnvelope />
                        {email}
                      </a>
                    ),
                )}
              </div>
            </div>
          </div>
          <div className='column-item'>
            {item.types &&
              item?.types?.map(type => (
                <div className='info-type label-item-rounded-info-sm'>
                  {typeof type !== 'string' && type?.title}
                </div>
              ))}
          </div>
          <div className='line-border'></div>
          <div className='info-icons'>
            <button
              className='button-square-md'
              data-tooltip-id='add-activity'
              data-tooltip-content='Adauga activitate'
              aria-label=''
              onClick={e => {
                e.preventDefault();
                navigate('/add-activity?contact=' + item.id);
              }}
            >
              <IconAddition />
            </button>
            <Tooltip id='add-activity' />
            <button
              className='button-square-md'
              data-tooltip-id='remove'
              data-tooltip-content='Sterge contact'
              aria-label=''
              onClick={e => {
                e.preventDefault();
                setShowDeleteModal(true);
              }}
            >
              <IconTrash />
            </button>
            <Tooltip id='remove' />
            <button
              className='button-square-md'
              aria-label=''
              data-tooltip-id='edit'
              data-tooltip-content='Modifica contact'
              onClick={e => {
                e.preventDefault();
                navigate('/contacts/' + item.id, {
                  state: {
                    isFromAgencyList: location.pathname === '/agency-contacts',
                  },
                });
              }}
            >
              <IconPencil />
            </button>
            <Tooltip id='edit' />
          </div>
        </div>
        <ModalConfirm
          showModal={showDeleteModal}
          title='Stergere contact'
          body='Esti sigur ca doresti sa stergi contactul?'
          onConfirmed={() => removeItem(item.id)}
          hideModal={() => setShowDeleteModal(false)}
        />
      </div>
    )
  );
};

export default ContactsListItem;

import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { getFilePath } from '../../helpers/useHelper';
import Avatar from '../../assets/img/avatar.png';
import House from '../../assets/img/house.png';
import CloseIcon from '../../assets/svg/closemodal.svg';
import IconEnvelope from '../../icons/IconEnvelope';
import IconPhone from '../../icons/IconPhone';
import './RequestEmailModal.scss';

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
  formData: any;
  submitForm: () => void;
}

const RequestEmailModal: React.FC<Props> = ({
  isModalOpen,
  closeModal,
  formData,
  submitForm,
}) => {
  return (
    <>
      <Modal
        show={isModalOpen}
        onHide={closeModal}
        animation={true}
        className={'mobile-modal request-email-modal'}
      >
        <Modal.Header>
          <div className='close-btn-modal' onClick={closeModal}>
            <img src={CloseIcon} alt='CloseIcon' />
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className='request-modal'>
            <div className='request-modal__title'>Previzualizare E-mail</div>
            <div className='request-modal__contacts'>
              {formData.contacts
                ?.filter((contact: any) =>
                  contact.emails.find((em: any) => em.checked),
                )
                ?.map((contact: any) => {
                  return (
                    <div className='request-modal__contact-item'>
                      <div className='img-block'>
                        {contact.avatarPath ? (
                          <img src={getFilePath(contact.avatarPath)} alt='' />
                        ) : (
                          <img src={Avatar} alt='' />
                        )}
                      </div>
                      <div className='info-contact'>
                        <span className='name'>
                          {contact.name + ' ' + contact.surname}
                        </span>
                        <div className='info-item'>
                          {contact.internationalPhoneNumber && (
                            <a
                              href={'tel:' + contact.internationalPhoneNumber}
                              aria-label=''
                              className='number'
                            >
                              <IconPhone />
                              {contact.internationalPhoneNumber}
                            </a>
                          )}
                        </div>
                        {contact.emails
                          .filter((email: any) => email.checked)
                          .map((email: any) => (
                            <div className='info-item'>
                              <a
                                href={'mailto:' + email.email}
                                aria-label=''
                                className='number'
                              >
                                <IconEnvelope />
                                {email.email}
                              </a>
                            </div>
                          ))}
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className='request-modal__title'>{formData.title}</div>
            <div className='request-modal__subtitle'>{formData.comment}</div>
            <div className='request-modal__properties'>
              {formData.properties?.map((property: any) => {
                return (
                  <div className='request-modal__property-item'>
                    <div className='img-block'>
                      {property.media.find(
                        (img: any) => img.type === 'MAIN',
                      ) ? (
                        <img
                          src={getFilePath(
                            property.media.find(
                              (img: any) => img.type === 'MAIN',
                            ).media?.path,
                          )}
                          alt=''
                        />
                      ) : (
                        <img src={House} alt='' />
                      )}
                    </div>
                    <div className='info-contact'>
                      <span className='name'>{property.title}</span>
                      <span className='description'>
                        {property.description}
                      </span>
                      <div className='pills-details'>
                        {property?.filterSurfaceUValue && (
                          <span className='label-item-tag'>
                            SU: {property?.filterSurfaceUValue}mp
                          </span>
                        )}

                        {property?.filterStaircaseValue && (
                          <span className='label-item-tag'>
                            Etaj: {property?.filterStaircaseValue}
                          </span>
                        )}
                        {property?.filterYearValue && (
                          <span className='label-item-tag'>
                            AN: {property?.filterYearValue}
                          </span>
                        )}
                      </div>
                      <div className='price'>
                        {property?.prices &&
                          property?.prices?.length > 0 &&
                          property?.prices.map((price: any) => {
                            if (price.id === 1 && price.sellingPrice)
                              return (
                                <div className='display-flex gap-10'>
                                  <div className=' label-item-mobile'>
                                    {price.sellingPrice} {property.currencySymbol ?? '€'}
                                  </div>
                                </div>
                              );
                            if (price.id === 2 && price.rentalMonthPrice)
                              return (
                                <div className='display-flex gap-10'>
                                  <div className=' label-item-mobile'>
                                    {price.rentalMonthPrice} {property.currencySymbol ?? '€'} / luna
                                  </div>
                                </div>
                              );
                            if (price.id === 3 && price.rentalDayPrice)
                              return (
                                <div className='display-flex gap-10'>
                                  <div className=' label-item-mobile'>
                                    {price.rentalDayPrice} {property.currencySymbol ?? '€'} / zi
                                  </div>
                                </div>
                              );
                            if (price.id === 4 && price.sellingPriceSqm)
                              return (
                                <div className='display-flex gap-10'>
                                  <div className=' label-item-mobile'>
                                    {price.sellingPriceSqm} {property.currencySymbol ?? '€'} / mp
                                  </div>
                                </div>
                              );
                            if (price.id === 5 && price.rentalMonthPriceSqm)
                              return (
                                <div className='display-flex gap-10'>
                                  <div className=' label-item-mobile'>
                                    {price.rentalMonthPriceSqm} {property.currencySymbol ?? '€'} / mp
                                  </div>
                                </div>
                              );
                            else return null;
                          })}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className='btns-container'>
              <button className='reset-btn' onClick={closeModal}>
                Renunta
              </button>
              <button
                className='button-blue button-md'
                onClick={() => submitForm()}
              >
                Trimite
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default RequestEmailModal;

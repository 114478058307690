import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getUsers } from '../../redux/users/usersThunk';
import { getFilePath } from '../../helpers/useHelper';
import ProfileImg from '../../assets/img/avatar.png';
import IconEnvelope from '../../icons/IconEnvelope';
import IconPhone from '../../icons/IconPhone';
import Input from '../Input';
import Spinner from '../Spinner';
import './SlotAllocationTable.scss';

interface Props {}

const SlotAllocationTable: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const initialized = useRef(false);

  const { users, loading } = useAppSelector(state => state.users);

  useEffect(() => {
    if (!initialized.current) {
      initialized.current = true;
      getLocalAgents();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLocalAgents = () => {
    dispatch(
      getUsers({
        roles: 'AGENCY',
        limit: '-1',
        offset: 1,
      }),
    );
  };

  console.log(users);

  return (
    <div className='slot-allocation-wrapper'>
      {loading ? (
        <div className='container'>
          <Spinner />
        </div>
      ) : (
        <>
          <div className='slot-allocation-table'>
            <div className='slot-allocation-item desktop-titles'>
              <div className='slot-column'>Agent</div>
              <div className='slot-column'>Basic</div>
              <div className='slot-column'>Premium</div>
              <div className='slot-column'>Up!</div>
              <div className='slot-column'>Spotlight</div>
            </div>
            <div className='slot-allocation-item-total'>
              <div className='slot-column-line'>
                <div className='slot-column'>Total Alocate</div>
                <div className='column-item'>
                  <div className='mobile-text'>Agent</div>
                  <div className='slot-column'>
                    <div className='content-numbers'>
                      <span className='pill'>28</span>971 ramase
                    </div>
                  </div>
                </div>
                <div className='column-item'>
                  <div className='mobile-text'>Basic</div>
                  <div className='slot-column'>
                    <div className='content-numbers'>
                      <span className='pill'>17</span>3 ramase
                    </div>
                  </div>
                </div>
                <div className='column-item'>
                  <div className='mobile-text'>Premium</div>
                  <div className='slot-column'>
                    <div className='content-numbers'>
                      <span className='pill'>17</span>23 ramase
                    </div>
                  </div>
                </div>
                <div className='column-item'>
                  <div className='mobile-text'>Up!</div>
                  <div className='slot-column'>
                    <div className='content-numbers'>
                      <span className='pill'>7</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {users?.map((user, index) => (
              <div className='slot-allocation-item' key={'agent-' + index}>
                <div className='mobile-text'>Agent</div>
                <div className='column-item'>
                  <div className='contact-details'>
                    <div className='info-img'>
                      <img
                        src={
                          user?.avatarPath
                            ? getFilePath(user.avatarPath)
                            : ProfileImg
                        }
                        alt='ProfileIcon'
                      />
                    </div>
                    <div className='info-contact-details'>
                      {user?.fullName && (
                        <div className='name'>{user?.fullName}</div>
                      )}
                      <div className='flex-details'>
                        {user?.phoneNumbers?.length > 0 &&
                          user.phoneNumbers
                            .filter(phone => phone)
                            .map(phone => (
                              <a href='tel:' aria-label='' className='number'>
                                <IconPhone />
                                {phone}
                              </a>
                            ))}
                        {user?.email && (
                          <a href='mailto:' aria-label='' className='mail'>
                            <IconEnvelope />
                            {user?.email}
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='column-item'>
                  <div className='mobile-text'>Basic</div>
                  <div className='slot-column'>
                    <div className='content-numbers'>
                      <span className='pill'>
                        <Input type='number' value={0} classNameInput='pill' />
                      </span>
                      0 folosite
                    </div>
                  </div>
                </div>
                <div className='column-item'>
                  <div className='mobile-text'>Premium</div>
                  <div className='slot-column'>
                    <div className='content-numbers'>
                      <span className='pill'>
                        <Input type='number' value={0} classNameInput='pill' />
                      </span>
                      0 folosite
                    </div>
                  </div>
                </div>
                <div className='column-item'>
                  <div className='mobile-text'>Up!</div>
                  <div className='slot-column'>
                    <div className='content-numbers'>
                      <span className='pill'>
                        <Input type='number' value={0} classNameInput='pill' />
                      </span>
                      0 folosite
                    </div>
                  </div>
                </div>
                <div className='column-item'>
                  <div className='mobile-text'>Spotlight</div>
                  <div className='slot-column'>
                    <div className='content-numbers'>
                      <span className='pill'>
                        <Input type='number' value={0} classNameInput='pill' />
                      </span>
                      0 folosite
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className='btns-container-wrapper'>
            <div className='btns-container'>
              <button className='button-blue button-md'>Salveaza</button>
              <button className='reset-btn'>Reseteaza</button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SlotAllocationTable;

import React, { useCallback, useEffect, useState } from 'react';
import {
  ClearIndicatorProps,
  DropdownIndicatorProps,
  components,
} from 'react-select';
import AsyncSelect from 'react-select/async';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getProperties } from '../../redux/properties/propertiesThunk';
import useDebounce from '../../helpers/useDebounce';
import { getFilePath, parseValues } from '../../helpers/useHelper';
import { SelectValue } from '../../models/Properties';
import House from '../../assets/img/house.png';
import Search from '../../assets/svg/Search.svg';
import CalculatorIcon from '../../assets/svg/asociate.svg';
import IconClear from '../../icons/IconClear';
import './ProprietySelect.scss';

type DebouncedFunction<T extends (...args: any[]) => any> = (
  ...args: Parameters<T>
) => void;

const debounce = <T extends (...args: any[]) => any>(
  func: T,
  wait: number,
): DebouncedFunction<T> => {
  let timeout: NodeJS.Timeout;
  return function (...args: Parameters<T>) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};

const Option = (props: any) => {
  const { properties } = useAppSelector(state => state.properties);
  const property = properties.find(
    property => property?.id === Number(props.value),
  );
  const image =
    property?.media?.filter(
      media => media.status === 1 && media.type === 'MAIN' && media.media?.path,
    ) &&
    property?.media?.filter(
      media => media.status === 1 && media.type === 'MAIN' && media.media?.path,
    ).length > 0
      ? property?.media?.filter(
          media =>
            media.status === 1 && media.type === 'MAIN' && media.media?.path,
        )[0] ?? ''
      : null;

  return (
    <div>
      <components.Option {...props}>
        <div className='sugested-properties-item'>
          <div className='img-block'>
            {image && image.media?.path ? (
              <img src={getFilePath(image?.media?.path)} alt='' />
            ) : (
              <img src={House} alt='' />
            )}
          </div>
          <div className='info-properties'>
            <div className='details'>
              {property?.title ?? property?.categoryTitle}
            </div>
            {property?.prices &&
              property?.prices?.length > 0 &&
              property?.prices.map(price => {
                if (price.id === 1 && price.sellingPrice)
                  return (
                    <div className='price'>
                      <span>Pret vanzare: </span>
                      {price.sellingPrice} {property.currencySymbol ?? '€'}
                    </div>
                  );
                if (price.id === 2 && price.rentalMonthPrice)
                  return (
                    <div className='price'>
                      <span>Pret inchiriere: </span>
                      {price.rentalMonthPrice} {property.currencySymbol ?? '€'} / luna
                    </div>
                  );
                if (price.id === 3 && price.rentalDayPrice)
                  return (
                    <div className='price'>
                      <span>Pret regim hotelier: </span>
                      {price.rentalDayPrice} {property.currencySymbol ?? '€'} / zi
                    </div>
                  );
                if (price.id === 4 && price.sellingPriceSqm)
                  return (
                    <div className='price'>
                      <span>Pret vanzare mp: </span>
                      {price.sellingPriceSqm} {property.currencySymbol ?? '€'} / mp
                    </div>
                  );
                if (price.id === 5 && price.rentalMonthPriceSqm)
                  return (
                    <div className='price'>
                      <span>Pret inchiriere mp: </span>
                      {price.rentalMonthPriceSqm} {property.currencySymbol ?? '€'} / mp
                    </div>
                  );
                else return null;
              })}
            {property?.employeePhoneNumbers?.[0] && (
              <div className='price'>
                <span>Contact Proprietar </span>
                {property?.employeePhoneNumbers?.[0]}
              </div>
            )}
            <span className='label-item-rounded-info'>
              <img src={CalculatorIcon} alt='' />
              {property?.id}
            </span>
          </div>
        </div>
      </components.Option>
    </div>
  );
};
const SearchIndicator: React.FC<DropdownIndicatorProps> = props => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={Search} alt='' />
    </components.DropdownIndicator>
  );
};

const ClearIndicator: React.FC<ClearIndicatorProps> = props => {
  const { children = <IconClear />, innerProps } = props;
  return (
    <div {...innerProps}>
      <div style={{ padding: '0px 5px' }}>{children}</div>
    </div>
  );
};

const ProprietySelect: React.FC<any> = ({
  labelText,
  inlineLabel,
  id,
  value,
  clearOnSelect,
  onMenuClose,
  searchIndicator,
  searchByCharacter,
  ...props
}) => {
  const { properties } = useAppSelector(state => state.properties);
  const [values, setValues] = useState<SelectValue | null>();
  const [inputText, setInputText] = useState<string>('');
  const debouncedUserSearch = useDebounce(inputText, 1000);
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const selectProps = {
    values: values,
  };
  const [components, setComponents] = useState<any>({
    ClearIndicator: ClearIndicator,
  });
  useEffect(() => {
    setValues(value === '' ? null : value);
  }, [value]);

  const loadOptions = useCallback(
    debounce(
      (inputValue: string, callback: (options: SelectValue[]) => void) => {
        if (inputValue.length > 2) {
          return dispatch(getProperties({ advancedQuery: inputValue, status: '1,2,3,4,0' })).then(
            res => {
              return callback(parseValues(res.payload.data));
            },
          );
        } else {
          return callback([]);
        }
      },
      600,
    ),
    [],
  );

  const loadOptionsByCharacter = useCallback(
    debounce(
      (inputValue: string, callback: (options: SelectValue[]) => void) => {
        if (inputValue.length > 0) {
          return dispatch(getProperties({ 'filters[54]': inputValue, status: '1,2,3,4,0' })).then(
            res => {
              return callback(parseValues(res.payload.data));
            },
          );
        } else {
          return callback([]);
        }
      },
      600,
    ),
    [debouncedUserSearch],
  );

  useEffect(() => {
    if (searchIndicator)
      setComponents({
        ClearIndicator: ClearIndicator,
        DropdownIndicator: SearchIndicator,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchIndicator]);

  const defaultOptions = parseValues(properties.slice(0, 10));

  return (
    <>
      <div className='form-item-group multiselect'>
        {labelText && (
          <label className='form-item-group-label' htmlFor={id}>
            {inlineLabel}
          </label>
        )}
        <AsyncSelect
          {...selectProps}
          components={{
            Option,
            ...components,
          }}
          cacheOptions={searchByCharacter ? false : true}
          loadOptions={searchByCharacter ? loadOptionsByCharacter : loadOptions}
          defaultOptions={defaultOptions}
          autoFocus={false}
          onChange={(e: SelectValue) => {
            setValues(e);
          }}
          onInputChange={e => {
            setInputText(e);
          }}
          noOptionsMessage={e => {
            if (properties?.length === 0) return 'No options';
          }}
          placeholder={'Cauta'}
          value={values}
          menuIsOpen={open}
          onMenuOpen={() => setOpen(true)}
          onMenuClose={() => {
            onMenuClose && onMenuClose();
            if (clearOnSelect) setValues(null);

            setOpen(false);
          }}
          isClearable={false}
          isSearchable={true}
          {...props}
          classNames={{
            control: state =>
              state.isFocused
                ? 'form-item-control-select'
                : 'form-item-control-select',
          }}
          classNamePrefix='react-select'
        />
      </div>
    </>
  );
};

export default ProprietySelect;

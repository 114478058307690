import parse from 'html-react-parser';
import moment from 'moment/moment';
import React, { useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { getFilePath } from '../../../helpers/useHelper';
import { Announce, hasValue } from '../../../models/Announces';
import ListItemImg from '../../../assets/img/house_square.png';
import IconArrowDown from '../../../icons/IconArrowDown';
import IconDiskette from '../../../icons/IconDiskette';
import IconLabe from '../../../icons/IconLabel';
import IconPriceHistory from '../../../icons/IconPriceHistory';
import GalleryModal from '../../GalleryModal';
import PricesHistoryModal from '../../PricesHistoryModal';
import './AnouncesListCard.scss';

interface Props {
  item: Announce;
  handleCheckboxChange?: (id: number) => void;
  onActionClick: () => void;
  save: () => void;
  showLabels: () => void;
}

const AnouncesListCard: React.FC<Props> = ({ item, handleCheckboxChange, onActionClick, save, showLabels }) => {
  const [extendCard, setExtendCard] = useState(false);
  const [isOpened, setOpened] = useState(false);
  const [isCollapsedDescription, setIsCollapsedDescription] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(true);
  const [openedPrices, setPricesModal] = useState(false);

  const descriptionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setIsOverflowing((descriptionRef.current?.clientHeight ?? 0) > 120);
    setIsCollapsedDescription((descriptionRef.current?.clientHeight ?? 0) > 120);
  }, [item]);

  const closeModal = () => {
    setOpened(false);
  };

  const handleExtendCard = () => {
    setExtendCard(!extendCard);
  };
  return (
    <>
      <div className={`announces-card-container ${item.checked ? 'checked' : ''}`}>
        <div className='checkbox-container'>
          <span className='line'></span>
          <Form.Group id='formGridCheckbox'>
            <Form.Check
              type='checkbox'
              checked={item?.checked}
              readOnly
              onClick={() => handleCheckboxChange && handleCheckboxChange(item.id)}
            />
          </Form.Group>
          <span className='line'></span>
        </div>
        <div className='item-details-container'>
          <div className='img-title-section'>
            <div className='img-container' onClick={() => setOpened(true)}>
              <img
                src={
                  item.media && item.media.length > 0
                    ? getFilePath(item.media[0].media.path)
                    : ListItemImg
                }
                alt='img-item'
              />
            </div>
            <div className='details-container'>
              <div className='title-text'>{item.zoneLabel && `Zona ${item.zoneLabel}`}</div>
              <div className='rent-text'>
                {item.prices && item.prices.length > 0 && (
                  <>
                    <span>{item.prices.at(-1).id === 1 ? 'VANZARE' : 'INCHIRIERE'}</span>
                    {item.price} {item.currencySymbol}
                  </>
                )}
              </div>
            </div>
          </div>
          <div className='description-text'>
            <div ref={descriptionRef} className={`text-container ${isCollapsedDescription ? 'collapsed' : ''}`}>
              {parse(item.description)}
            </div>
            {isOverflowing && (
              <button className='toggle-button' onClick={() => setIsCollapsedDescription(!isCollapsedDescription)}>
                {isCollapsedDescription ? 'Afiseaza toata descrierea' : 'Afiseaza mai putin'}
              </button>
            )}
          </div>
          <div className='pills-details'>
            {item.phones &&
              item.phones.map((phone: any, index) => (
                <span key={`phone-${index}`} className='label-item-tag'>
                  {phone}
                </span>
              ))}
            {item.usableSurface && <span className='label-item-tag'>SU: {item.usableSurface}</span>}
            {item.floorNumber && <span className='label-item-tag'>Etaj: {item.floorNumber}</span>}
            {item.constructionYear && <span className='label-item-tag'>AN: {item.constructionYear}</span>}
            {hasValue(item.comfort) && <span className='label-item-tag'>Confort: {item.comfort}</span>}
            {hasValue(item.floors) && <span className='label-item-tag'>Nr etaje: {item.floors}</span>}
            {hasValue(item.numberOfRooms) && <span className='label-item-tag'>Nr camere: {item.numberOfRooms}</span>}
          </div>
          <div className='links-pils'>
            {item.sources &&
              item.sources
                .filter(
                  (source, index, self) =>
                    source.link !== '' &&
                    source.link !== '#' && // filter empty links
                    index === self.findIndex(s => s.link === source.link), // filter duplicated links
                )
                .map((source: any, index) => (
                  <a
                    href={source.link}
                    key={`source-${index}`}
                    target='_blank'
                    className='label-item-link'
                    rel='noreferrer'>
                    {source.name}
                  </a>
                ))}
          </div>
          {extendCard && (
            <div className='hidden-content'>
              <div className='line-divide'></div>
              <div className='display-flex about-details'>
                <div className='item-detail'>
                  <span>Localitate</span>
                  <span className='label-item-tag'>{item.townTitle ?? item.countyTitle}</span>
                </div>
                <div className='item-detail'>
                  <span>Tip teren</span>
                  <span className='label-item-tag'>{item.categoryTitle}</span>
                </div>
                <div className='item-detail'>
                  <span>Supraf teren</span>
                  <span className='label-item-tag'>{item.landSurface}</span>
                </div>
                <div className='item-detail'>
                  <span>Front stradal</span>
                  <span className='label-item-tag'>{item.streetFrontDistance}</span>
                </div>
                <div className='item-detail'>
                  <span>Pret</span>
                  <span className='label-item-tag'>
                    {item.price} {item.currencySymbol}
                  </span>
                </div>
                <div className='item-detail'>
                  <span>Actualizat</span>
                  <span className='label-item-tag'>
                    {moment(item.dateUpdated ?? item.dateCreated).format('DD.MM.YYYY - HH:mm')}
                  </span>
                </div>
              </div>
              <div className='line-divide'></div>
              <div className='actions-container'>
                <div className='icons-container'>
                  <button className='button-square-md' title='Istoric pret' onClick={() => setPricesModal(true)}>
                    <IconPriceHistory />
                  </button>
                  <button
                    className='button-square-md'
                    onClick={() => {
                      onActionClick();
                      showLabels();
                    }}
                    title='Etichete'>
                    <IconLabe />
                  </button>
                  <button className='button-square-md' title='Clonare' onClick={save}>
                    <IconDiskette />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
        <button className={`more-button ${extendCard ? '' : 'open'}`} onClick={handleExtendCard}>
          <span className='more-button-border'>
            <span className='arrow-icon'>
              <IconArrowDown />
            </span>
          </span>
          <span>{extendCard ? 'Mai putin' : 'Mai mult'}</span>
        </button>

        <GalleryModal
          closeModal={closeModal}
          isModalOpen={isOpened}
          images={item?.media?.filter(media => media.status === 1 && media.media?.path) ?? []}
        />

        <PricesHistoryModal
          closeModal={() => setPricesModal(false)}
          isModalOpen={openedPrices}
          prices={item?.prices ?? []}
        />
      </div>
    </>
  );
};

export default AnouncesListCard;

import moment from 'moment';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonSquare from '../../components/ButtonSquare';
import Spinner from '../../components/Spinner';
import { useAppSelector } from '../../redux/hooks';
import { getFilePath } from '../../helpers/useHelper';
import Avatar from '../../assets/img/avatar.png';
import CalculatorIcon from '../../assets/svg/asociate.svg';
import IconAdd from '../../icons/IconAdd';
import IconUser from '../../icons/IconUser';
import './ContactEditInfo.scss';

const ContactEditInfo: React.FC = () => {
  const navigate = useNavigate();
  const { contact, loading, topProperties, topActivities } = useAppSelector(
    state => state.contacts,
  );

  return (
    <div className='display-flex ads-form-container'>
      {loading ? (
        <Spinner />
      ) : (
        <div className='contact-edit-container'>
          <div className='contact-edit-container--info'>
            <div className='contact-edit-container--title'>
              {contact?.name + ' ' + contact?.surname}
            </div>
            <div className='contact-edit-container--left'>
              {contact?.avatar?.path ? (
                <img src={getFilePath(contact?.avatar.path)} alt='' />
              ) : (
                <img src={Avatar} alt='' />
              )}
              <div className='contact-edit__heading'>
                Creat:{' '}
                <span>
                  {contact?.dateCreated
                    ? moment(contact?.dateCreated).format('DD.MM.YYYY')
                    : ' - '}
                </span>
              </div>
              <div className='contact-edit__heading'>
                Modificat:{' '}
                <span>
                  {contact?.dateCreated
                    ? moment(contact?.dateCreated).format('DD.MM.YYYY')
                    : ' -'}
                </span>
              </div>
            </div>

            <div
              className={`contact-edit-container--right item-details-container `}
            >
              <div className='contact-edit-list'>
                {contact?.company && (
                  <div className='contact-edit-item'>
                    <div className='contact-edit__heading'>Companie:</div>
                    <div className='contact-edit__content label-item-rounded-info-sm'>
                      {contact?.company?.title}
                    </div>
                  </div>
                )}
                {contact?.phoneNumbers &&
                  contact?.phoneNumbers?.length > 0 &&
                  contact?.phoneNumbers[0]?.phone.length > 0 && (
                    <div className='contact-edit-item'>
                      <div className='contact-edit__heading'>Tel:</div>
                      <div className='contact-edit__content label-item-rounded-info-sm'>
                        {contact?.phoneNumbers
                          .map(phone => phone.phone)
                          .join(', ')}
                      </div>
                    </div>
                  )}
                {contact?.internationalPhoneNumber && (
                  <div className='contact-edit-item'>
                    <div className='contact-edit__heading'>
                      Tel international:
                    </div>
                    <div className='contact-edit__content label-item-rounded-info-sm'>
                      {contact?.internationalPhoneNumber}
                    </div>
                  </div>
                )}
                {contact?.emails &&
                  contact?.emails.length > 0 &&
                  contact?.emails[0].length > 0 && (
                    <div className='contact-edit-item'>
                      <div className='contact-edit__heading'>E-mail:</div>
                      <div className='contact-edit__content label-item-rounded-info-sm'>
                        {contact?.emails.join(' ')}
                      </div>
                    </div>
                  )}
                {contact?.address && (
                  <div className='contact-edit-item'>
                    <div className='contact-edit__heading'>Adresa:</div>
                    <div className='contact-edit__content label-item-rounded-info-sm'>
                      {contact?.address}
                    </div>
                  </div>
                )}
                {contact?.associatedEmployees &&
                  contact?.associatedEmployees.length > 0 && (
                    <div className='contact-edit-item'>
                      <div className='contact-edit__heading'>
                        Agenti Asociati:
                      </div>
                      <div className='contact-edit__content label-item-rounded-info-sm'>
                        {contact?.associatedEmployees
                          ?.map(employee => employee.profile?.fullName)
                          .join(', ')}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className='contact-edit-container--info'>
            <div className='contact-edit-container--header'>
              <div className='contact-edit-container--title'>
                Dashboard activitati
              </div>
              <div className='icon-container'>
                <IconUser />
              </div>
            </div>
            <div>
              Pentru o mai buna evidentiere, completeaza cat mai corect toate
              informatiile suplimentare.
            </div>
          </div>
          <div className='contact-edit-container--info'>
            <div className='contact-edit-container--header'>
              <div className='contact-edit-container--title'>Activitati</div>{' '}
              <div
                onClick={() => navigate('/add-activity?contact=' + contact?.id)}
              >
                <IconAdd />
              </div>
            </div>
            <div className='contact-edit-activities'>
              <div className='activity-list--header'>
                <div className='activity-list--header-item'>Data</div>
                <div className='activity-list--header-item'>Activitate</div>
                <div className='activity-list--header-item'>Subiect</div>
                <div className='activity-list--header-item'>
                  Propeietate / Solicitare
                </div>
                <div className='activity-list--header-item'>Actiuni</div>
              </div>
              {topActivities?.length > 0 && (
                <div className='activity-list'>
                  {topActivities.map(activity => (
                    <div
                      className={`activity-list-item bg-blue ${activity.status === 5 ? 'opacity' : ''}`}
                      key={activity.id}
                    >
                      <div className='activity-list-item--column'>
                        {moment(activity.dateStart).format('DD.MM.YYYY')}

                        <div className='label-item-rounded-info-sm'>
                          {' '}
                          {moment(activity.dateStart).format('HH:mm')}
                        </div>
                      </div>
                      <div className='activity-list-item--column'>
                        {activity.activityTypeIconPath && (
                          <div className='icon'>
                            <img
                              src={getFilePath(activity.activityTypeIconPath)}
                              alt={`${activity.id}`}
                            />
                          </div>
                        )}
                      </div>{' '}
                      <div className='activity-list-item--column'>
                        <div className='content'>
                          <h3>{activity.typeTitle + ': ' + activity.title}</h3>
                          {activity.details && <p>{activity.details}</p>}{' '}
                        </div>
                      </div>
                      <div className='activity-list-item--column'>
                        <div className='info-details'>
                          {activity.properties?.map(property => (
                            <button
                              className='label-item-rounded-info'
                              onClick={() =>
                                navigate('/properties/' + property.id)
                              }
                            >
                              <img src={CalculatorIcon} alt='' />
                              {property.id}
                            </button>
                          ))}
                        </div>
                      </div>
                      <div className='activity-list-item--column'>
                        <ButtonSquare
                          btnSize='md'
                          checkedBtn={activity.status !== 5}
                          renames={true}
                          editAction={() => {
                            navigate('/activities/' + activity.id);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className='contact-edit-container--info'>
            <div className='contact-edit-container--header'>
              <div className='contact-edit-container--title'>Proprietati</div>{' '}
              <div onClick={() => navigate('/add-property')}>
                <IconAdd />
              </div>
            </div>
            <div className='contact-edit-properties'>
              <div className='property-list--header'>
                <div className='property-list--header-item'>Status</div>
                <div className='property-list--header-item'>Foto</div>
                <div className='property-list--header-item'>Adresa</div>
                <div className='property-list--header-item'>
                  Detalii Proprietate{' '}
                </div>
                <div className='property-list--header-item'>Pret</div>
                <div className='property-list--header-item'>Actiuni</div>
              </div>
              {topProperties?.length > 0 && (
                <div className='property-list'>
                  {topProperties.map(property => (
                    <div
                      className={`property-list-item bg-blue`}
                      key={property.id}
                    >
                      <div className='property-list-item--column'>
                        <div className='label-item-rounded-info'>
                          {property?.status}
                        </div>
                      </div>
                      <div className='property-list-item--column'>
                        {property?.media?.filter(
                          image => image.type === 'MAIN',
                        )[0]?.media?.path && (
                          <div className='image'>
                            <img
                              src={getFilePath(
                                property?.media?.filter(
                                  image => image.type === 'MAIN',
                                )[0]?.media?.path ?? '',
                              )}
                              alt={`${property?.id}`}
                            />
                          </div>
                        )}
                      </div>{' '}
                      <div className='property-list-item--column'>
                        <div className='info-adress'>
                          {property?.townTitle && (
                            <div className='display-flex column-item'>
                              <span>Localitate: </span>
                              <span className=' label-item-mobile'>
                                {property?.townTitle}
                              </span>
                            </div>
                          )}
                          {property?.zoneTitle && (
                            <div className='display-flex column-item'>
                              <span>Zona: </span>
                              <span className=' label-item-mobile'>
                                {property?.zoneTitle}
                              </span>
                            </div>
                          )}
                          {property?.streetTitle && (
                            <div className='display-flex column-item'>
                              <span>Adresa: </span>
                              <span className='label-item-mobile '>
                                {'str. ' +
                                  property?.streetTitle +
                                  (property?.streetNumber
                                    ? ', nr. ' + property?.streetNumber
                                    : '') +
                                  (property?.staircase
                                    ? ', bl. ' + property?.staircase
                                    : '') +
                                  (property?.floorNumber
                                    ? ', ap. ' + property?.floorNumber
                                    : '')}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='property-list-item--column'>
                        <div className='info-properties'>
                          {property?.specificTypeTitle && (
                            <div className='display-flex column-item'>
                              <span>Tip: </span>
                              <span className=' label-item-mobile'>
                                {property?.specificTypeTitle}
                              </span>
                            </div>
                          )}
                          {property?.filterSurfaceUValue && (
                            <div className='display-flex column-item'>
                              <span>SU: </span>
                              <span className=' label-item-mobile '>
                                {property?.filterSurfaceUValue}
                              </span>
                            </div>
                          )}
                          {property?.filterSurfaceCValue && (
                            <div className='display-flex column-item'>
                              <span>SC: </span>
                              <span className=' label-item-mobile '>
                                {property?.filterSurfaceCValue}
                              </span>
                            </div>
                          )}
                          {property?.filterYearValue && (
                            <div className='display-flex column-item'>
                              <span>An: </span>
                              <span className=' label-item-mobile '>
                                {property?.filterYearValue}
                              </span>
                            </div>
                          )}
                          {property?.filterStaircaseValue && (
                            <div className='display-flex column-item'>
                              <span>Etaj: </span>
                              <span className=' label-item-mobile '>
                                {property?.filterStaircaseValue}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className='property-list-item--column property-list-item--column-price'>
                        {property?.prices &&
                          property?.prices?.length > 0 &&
                          property?.prices.map(price => {
                            if (price.id === 1 && price.sellingPrice)
                              return (
                                <div className='price'>
                                  <span>Pret vanzare: </span>
                                  {price.sellingPrice}{' '}
                                  {property.currencySymbol ?? '€'}
                                </div>
                              );
                            if (price.id === 2 && price.rentalMonthPrice)
                              return (
                                <div className='price'>
                                  <span>Pret inchiriere: </span>
                                  {price.rentalMonthPrice}{' '}
                                  {property.currencySymbol ?? '€'} / luna
                                </div>
                              );
                            if (price.id === 3 && price.rentalDayPrice)
                              return (
                                <div className='price'>
                                  <span>Pret regim hotelier: </span>
                                  {price.rentalDayPrice}{' '}
                                  {property.currencySymbol ?? '€'} / zi
                                </div>
                              );
                            if (price.id === 4 && price.sellingPriceSqm)
                              return (
                                <div className='price'>
                                  <span>Pret vanzare mp: </span>
                                  {price.sellingPriceSqm}{' '}
                                  {property.currencySymbol ?? '€'} / mp
                                </div>
                              );
                            if (price.id === 5 && price.rentalMonthPriceSqm)
                              return (
                                <div className='price'>
                                  <span>Pret inchiriere mp: </span>
                                  {price.rentalMonthPriceSqm}{' '}
                                  {property.currencySymbol ?? '€'} / mp
                                </div>
                              );
                            else return null;
                          })}
                      </div>
                      <div className='property-list-item--column'>
                        <ButtonSquare
                          btnSize='md'
                          renames={true}
                          editAction={() => {
                            navigate('/properties/' + property.id);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className='contact-edit-container--info'>
            <div className='contact-edit-container--header'>
              <div className='contact-edit-container--title'>Solicitări</div>{' '}
              <IconAdd />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ContactEditInfo;

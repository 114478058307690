import { createAsyncThunk } from '@reduxjs/toolkit';
import { Label } from '../../models/Labels';
import { appAxios } from '../axiosInstance';
import { handleError } from '../helpers';

type LabelsResponse = {
  labels: Label[];
  totalCount: number;
};

export const getLabels = createAsyncThunk<LabelsResponse, any>(
  'crm/getLabels',
  async (data, { rejectWithValue }) => {
    try {
      const response = await appAxios.get('api/v1/crm/labels', {
        params: data,
      });
      const totalCount = response.headers['x-total-count'];

      return { labels: response.data, totalCount };
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getRecommendedLabels = createAsyncThunk<Label[]>(
  'crm/getRecommendedLabels',
  async (_, { rejectWithValue }) => {
    try {
      const data = {
        limit: -1,
        offset: 0,
        top: 0,
      };

      const response = await appAxios.get('api/v1/crm/labels', {
        params: data,
      });

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const getPopularLabels = createAsyncThunk<Label[]>(
  'crm/getPopularLabels',
  async (_, { rejectWithValue }) => {
    try {
      const data = {
        limit: -1,
        offset: 0,
        top: 1,
      };

      const response = await appAxios.get('api/v1/crm/labels', {
        params: data,
      });

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const searchLabels = createAsyncThunk<Label[], string>(
  'crm/searchLabels',
  async (query, { rejectWithValue }) => {
    try {
      const data = {
        limit: 10,
        offset: 0,
        q: query,
      };

      const response = await appAxios.get('api/v1/crm/labels', {
        params: data,
      });

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const addLabel = createAsyncThunk<any, any>(
  'crm/labels/add',
  async (label, { rejectWithValue }) => {
    try {
      const response = await appAxios.post(`api/v1/crm/labels`, label);

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

export const removeLabel = createAsyncThunk<any, any>(
  'crm/labels/delete',
  async (label, { rejectWithValue }) => {
    try {
      const response = await appAxios.delete(`api/v1/crm/labels/${label}`);

      return response.data;
    } catch (error: any) {
      return handleError(error, rejectWithValue);
    }
  },
);

import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../redux/hooks';
import FiltreIcon from '../../assets/svg/filtericon.svg';
import IconArrowUpRight from '../../icons/IconArrowUpRight';
import './AlocatedSlots.scss';
import totalsData from './totals.json';

interface Props {}
interface TotalItem {
  value: string;
  left: string;
}
interface Totals {
  title: string;
  basic: TotalItem;
  premium: TotalItem;
  up: TotalItem;
  spotlight: TotalItem;
  video: TotalItem;
  virtualtur: TotalItem;
}

const AlocatedSlots: React.FC<Props> = () => {
  const { activeAgents } = useAppSelector(state => state.dashboard);
  const totals: Totals[] = totalsData as Totals[];

  return (
    <div className='alocated-slots-container'>
      <div className='table-item alocated-list-table'>
        <div className='slots-header display-flex'>
          <h5 className='title-section'>Sloturi alocate</h5>
          <div className='alocated-filters'>
            <div className='filter-container'>
              <input
                type='text'
                placeholder='Filter'
                className='form-item-control-filter'
              />
              <button
                className='form-item-control-button'
                aria-label='Filtreaza'
              >
                <img src={FiltreIcon} alt='filter' />
              </button>
            </div>
            <Link to='/' className='btn-blue'>
              Alocare sloturi
            </Link>
          </div>
        </div>
        <div className='alocated-table'>
          <Table striped hover>
            <thead>
              <tr>
                <th>Agent</th>
                <th>Basic</th>
                <th>Premium</th>
                <th>Up</th>
                <th>Spotlight</th>
                <th>Video</th>
                <th>Tur virtual</th>
              </tr>
              {totals?.map((total, index) => (
                <tr key={index} className='totals-row'>
                  <th>{total.title}</th>
                  <th>
                    <div className='total-item'>
                      <span className='total-value'>{total.basic.value}</span>
                      <p className='total-left'>{total.basic.left}</p>
                    </div>
                  </th>
                  <th>
                    <div className='total-item'>
                      <span className='total-value'>{total.premium.value}</span>
                      <p className='total-left'>{total.premium.left}</p>
                    </div>
                  </th>
                  <th>
                    <div className='total-item'>
                      <span className='total-value'>{total.up.value}</span>
                      <p className='total-left'>{total.up.left}</p>
                    </div>
                  </th>
                  <th>
                    <div className='total-item'>
                      <span className='total-value'>
                        {total.spotlight.value}
                      </span>
                      <p className='total-left'>{total.spotlight.left}</p>
                    </div>
                  </th>
                  <th>
                    <div className='total-item'>
                      <span className='total-value'>{total.video.value}</span>
                      <p className='total-left'>{total.video.left}</p>
                    </div>
                  </th>
                  <th>
                    <div className='total-item'>
                      <span className='total-value'>
                        {total.virtualtur.value}
                      </span>
                      <p className='total-left'>{total.virtualtur.left}</p>
                    </div>
                  </th>
                </tr>
              ))}
            </thead>
            <tbody>
              {activeAgents?.map((agent, index) => (
                <tr key={index}>
                  <td>
                    <Link to={`/edit-agent/${agent.id}`}>
                      {agent.fullName}
                      <IconArrowUpRight />
                    </Link>
                  </td>
                  <td>
                    <span>19/0</span>
                  </td>
                  <td>
                    <span>0/0</span>
                  </td>
                  <td>
                    <span>0/0</span>
                  </td>
                  <td>
                    <span>0/0</span>
                  </td>
                  <td>
                    <span>0/0</span>
                  </td>
                  <td>
                    <span>0/0</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default AlocatedSlots;

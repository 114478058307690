import moment from 'moment';
import React, { useState } from 'react';
import Gallery from '../../components/Gallery';
import GalleryModal from '../../components/GalleryModal';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { cloneProperty } from '../../redux/properties/propertiesThunk';
import { getFilePath, getFilter } from '../../helpers/useHelper';
import { PropertyForm } from '../../models/Properties';
import Avatar from '../../assets/img/avatar.png';
import './AdsGeneral.scss';

interface Props {
  formData: PropertyForm;
}

const AdsGeneral: React.FC<Props> = ({ formData }) => {
  const dispatch = useAppDispatch();
  const [isOpened, setOpened] = useState(false);

  const { property } = useAppSelector(state => state.properties);
  const { contacts } = useAppSelector(state => state.contacts);

  const handleClone = () => {
    property?.id &&
      dispatch(cloneProperty(property.id)).then(res => {
        if (res.payload.id)
          window.open(
            `${window.location.origin}/properties/${res.payload.id}?clone=true`,
          );
      });
  };

  const closeModal = () => {
    setOpened(false);
  };
  return (
    <div className='ads-general-sm'>
      <div className={`ads-general-container bg-white `}>
        <Gallery
          onClick={() => setOpened(true)}
          images={property?.media?.filter(
            media =>
              media.status === 1 && media.type === 'MAIN' && media.media?.path,
          )}
        />
        <div className={`ads-general-info-bottom `}>
          <div className={`ads-general-info-container item-details-container `}>
            <div className='ads-general-info'>
              <table>
                <tbody>
                  <tr>
                    <th className='ads-general-info__heading'>ID titirez:</th>
                    <td className='ads-general-info__content'>
                      {property?.id}
                    </td>
                  </tr>
                  {getFilter(8, property).exists && (
                    <tr>
                      <th className='ads-general-info__heading'>
                        Suprafata utila:
                      </th>
                      <td className='ads-general-info__content'>
                        {getFilter(8, property).value + ' mp'}
                      </td>
                    </tr>
                  )}
                  {getFilter(9, property).exists && (
                    <tr>
                      <th className='ads-general-info__heading'>
                        Suprafata constr.:{' '}
                      </th>
                      <td className='ads-general-info__content'>
                        {getFilter(9, property).value + ' mp'}
                      </td>
                    </tr>
                  )}

                  {getFilter(18, property).exists && (
                    <tr>
                      <th className='ads-general-info__heading'>
                        Compartimentare:
                      </th>
                      <td className='ads-general-info__content'>
                        {getFilter(18, property).value}
                      </td>
                    </tr>
                  )}
                  {getFilter(16, property).exists && (
                    <tr>
                      <th className='ads-general-info__heading'>Etaj:</th>
                      <td className='ads-general-info__content'>
                        {getFilter(16, property).value}
                      </td>
                    </tr>
                  )}
                  {getFilter(30, property).exists && (
                    <tr>
                      <th className='ads-general-info__heading'>
                        An constructie:
                      </th>
                      <td className='ads-general-info__content'>
                        {getFilter(30, property).value}
                      </td>
                    </tr>
                  )}

                  {/* <tr>
                    <th className='ads-general-info__heading'>Pret vanzare:</th>
                    <td className='ads-general-info__content'>160 000 $</td>
                  </tr> */}
                  {property?.dateCreated && (
                    <tr>
                      <th className='ads-general-info__heading'>Adaugat la:</th>
                      <td className='ads-general-info__content'>
                        {moment(property?.dateCreated).format('DD.MM.YYYY')}
                      </td>
                    </tr>
                  )}
                  {property?.dateUpdated && (
                    <tr>
                      <th className='ads-general-info__heading'>
                        Ultima modificare:
                      </th>
                      <td className='ads-general-info__content'>
                        {moment(property?.dateUpdated).format('DD.MM.YYYY')}
                      </td>
                    </tr>
                  )}
                  <tr>
                    <th className='ads-general-info__heading'>
                      Duplicare anunt:
                    </th>
                    <td
                      className='ads-general-info__content'
                      onClick={() => handleClone()}
                    >
                      <div className='link' aria-label='Cloneaza'>
                        Cloneaza
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className='user-general-info'>
              {property?.employee?.profile?.avatar?.path ? (
                <div className='image-block'>
                  <img
                    src={getFilePath(property?.employee?.profile?.avatar?.path)}
                    alt=''
                  />
                </div>
              ) : (
                <div className='image-block'>
                  <img src={Avatar} alt='' />
                </div>
              )}
              <div className='content-info'>
                <p className='name'>{property?.employee?.profile?.fullName}</p>
                <span className='info'>
                  {property?.employee?.profile?.phoneNumbers?.map(
                    (number, index) =>
                      number +
                      (index !==
                      property?.employee?.profile?.phoneNumbers.length - 1
                        ? ', '
                        : ''),
                  )}
                </span>
              </div>
            </div>
            <div className='associated-contacts'>
              <h3 className='heading'>Contacte asociate</h3>
              <ul className='associated-contacts-list'>
                {contacts &&
                  contacts.length > 0 &&
                  contacts?.map(item => {
                    if (
                      formData.contacts?.find(contact => contact.id === item.id)
                    )
                      return (
                        <li
                          className='associated-contacts-list__item'
                          key={item.id}
                        >
                          <a
                            href={'/contacts/' + item.id}
                            className='label-item-rounded-info-sm'
                            aria-label='Vezi pagina utilizatorului Lauren Jensen'
                          >
                            {item?.name + ' ' + item.surname}
                          </a>
                        </li>
                      );
                    else return null;
                  })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <GalleryModal
        closeModal={closeModal}
        isModalOpen={isOpened}
        images={
          property?.media?.filter(
            media =>
              media.status === 1 && media.type === 'MAIN' && media.media?.path,
          ) ?? []
        }
      />
    </div>
  );
};

export default AdsGeneral;

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { parseOrganization } from '../../redux/helpers';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { clearTowns, clearZona } from '../../redux/localities/localities-slice';
import {
  getCounties,
  getCounty,
  getLocalities,
  getZones,
} from '../../redux/localities/localitiesThunk';
import { uploadImage } from '../../redux/media/mediaThunk';
import {
  editOrganization,
  getOrganization,
} from '../../redux/users/usersThunk';
import {
  getFilePath,
  getValue,
  parseValues,
  showError,
} from '../../helpers/useHelper';
import { SelectValue } from '../../models/Properties';
import { OrganizationForm } from '../../models/Users';
import DeleteIcon from '../../assets/svg/deletebtn.svg';
import editIcon from '../../assets/svg/editbtn.svg';
import IconAdd from '../../icons/IconAdd';
import IconClear from '../../icons/IconClear';
import IconTrash from '../../icons/IconTrash';
import IconUploadPhoto from '../../icons/IconUploadPhoto';
import FormSelect from '../FormSelect';
import Input from '../Input/Input';
import MultiselectCheckboxes from '../MultiselectCheckboxes';
import Spinner from '../Spinner';
import './AddAgencySettings.scss';

interface Props {}

const AddAgencySettings: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { organizationState } = useAppSelector(state => state.users);
  const { county, counties, localities, zona } = useAppSelector(
    state => state.localities,
  );

  const [formData, setFormData] = useState<OrganizationForm>({
    phoneNumbers: [''],
  });
  const [profileImage, setProfileImage] = useState<string>('');

  const [countyId, setCountyId] = useState<number | null>(null);
  const [cityId, setCityId] = useState<number | null>(null);

  useEffect(() => {
    if (formData.countyId !== countyId) {
      dispatch(clearTowns());
      dispatch(clearZona());
      setFormData({
        ...formData,
        countyId: countyId ?? undefined,
        cityId: undefined,
        zones: [],
      });
    }
    if (countyId) {
      dispatch(getLocalities({ parent: countyId }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countyId]);

  useEffect(() => {
    if (formData.cityId !== cityId) {
      dispatch(clearZona());
      setFormData({ ...formData, cityId: cityId ?? undefined, zones: [] });
    }
    if (cityId) {
      dispatch(getZones({ parent: cityId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityId]);

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      dispatch(
        uploadImage({
          file: file,
          setProgress: (e: number) => {},
        }),
      ).then(res => {
        if (res.payload?.id) {
          setProfileImage(getFilePath(res.payload.filePath));
          setFormData((formData: any) => {
            formData.logoId = res.payload.id;
            return { ...formData };
          });
        }
      });
    }
  };

  const deleteProfileImage = () => {
    setProfileImage('');
    setFormData((formData: any) => {
      formData.logoId = null;

      return { ...formData };
    });
  };

  const cancel = () => {
    let parsedOrganization = {
      countyId: organizationState.organization?.county.id,
      zones: organizationState.organization?.zones?.map(zona => `${zona.id}`),
      counties: organizationState.organization?.counties?.map(
        county => `${county.id}`,
      ),
      cityId: organizationState.organization?.city.id,
      cui: organizationState.organization?.cui,
      cif: organizationState.organization?.cif,
      logo: organizationState.organization?.logo?.path,
      description: organizationState.organization?.description,
      address: organizationState.organization?.address,
      bank: organizationState.organization?.bank,
      bankAccount: organizationState.organization?.bankAccount,
      phoneEmployee: organizationState.organization?.phoneEmployee,
      email: organizationState.organization?.email,
      phoneNumbers: organizationState.organization?.phoneNumbers ?? [''],
      dateUpdated: organizationState.organization?.dateUpdated,
      dateCreated: organizationState.organization?.dateCreated,
      id: organizationState.organization?.id,
      status: organizationState.organization?.status,
      name: organizationState.organization?.name,
    };
    if (organizationState.organization?.county.id) {
      dispatch(
        getLocalities({ parent: organizationState.organization?.county.id }),
      );
      dispatch(clearZona());
    } else {
      dispatch(clearTowns());
      dispatch(clearZona());
      setFormData({ ...formData, cityId: undefined });
    }
    setFormData(parsedOrganization);
    setProfileImage(
      organizationState.organization?.logo?.path
        ? getFilePath(organizationState.organization?.logo?.path)
        : '',
    );
  };

  useEffect(() => {
    if (organizationState.organization) {
      if (organizationState.organization?.county?.id) {
        setCountyId(organizationState.organization?.county.id);
      }
      if (organizationState.organization?.city?.id) {
        setCityId(organizationState.organization?.city.id);
      }
      setFormData(parseOrganization(organizationState.organization));
      setProfileImage(
        organizationState.organization?.logo?.path
          ? getFilePath(organizationState.organization?.logo?.path)
          : '',
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationState.organization]);

  useEffect(() => {
    dispatch(getOrganization());
    dispatch(getCounty({ parent: 0 }));
    dispatch(getCounties());
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (
    key: string | number,
    e: string | number | SelectValue | boolean | null | string[],
  ) => {
    setFormData({ ...formData, [key]: e });
  };

  const submitAgency = () => {
    formData &&
      dispatch(editOrganization(formData)).then((res: any) => {
        if (res.payload?.id)
          toast.success('Setarile agentiei au fost editate cu succes!');
      });
  };

  return (
    <div className='add-agency-container'>
      {organizationState.loading ? (
        <Spinner />
      ) : (
        <>
          <div className='form-container'>
            <div className='img-upload-block'>
              <h4 className='title'>Informatii Agentie</h4>
              <div className='upload-container'>
                <label htmlFor='file-upload' className='custom-file-upload'>
                  {profileImage && profileImage?.length > 0 ? (
                    <img src={profileImage} alt='' />
                  ) : (
                    <IconUploadPhoto />
                  )}
                </label>
                <input
                  id='file-upload'
                  type='file'
                  accept='image/*'
                  onChange={handleImageChange}
                />
                {profileImage?.length > 0 && (
                  <>
                    <button
                      className='edit-btn'
                      aria-label='Editeaza'
                      onClick={() =>
                        document.getElementById('file-upload')?.click()
                      }
                    >
                      <img src={editIcon} alt='editIcon' />
                    </button>
                    <button
                      className='delete-btn'
                      aria-label='Sterge'
                      onClick={deleteProfileImage}
                    >
                      <img src={DeleteIcon} alt='DeleteIcon' />
                    </button>
                  </>
                )}{' '}
              </div>
            </div>
            <div className='form-block'>
              <form>
                <div className='form-line-section-half'>
                  <div className='form-line-section'>
                    <Input
                      labelText={true}
                      value={formData?.name ?? ''}
                      error={organizationState.error?.name}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFormData({ ...formData, name: e.target.value })
                      }
                      inlineLabel='Nume'
                    />
                    <div className='input-group'>
                      <div className='input-group phone-number'>
                        <label className='form-item-group-label'>Telefon</label>
                        <div className='input-group-container'>
                          {formData?.phoneNumbers?.map((phoneNumber, index) => (
                            <Input
                              labelText={false}
                              value={phoneNumber ?? ''}
                              error={organizationState.error?.phoneNumbers}
                              onInput={(
                                e: React.ChangeEvent<HTMLInputElement>,
                              ) => {
                                setFormData(() => {
                                  const newPhones = formData.phoneNumbers?.map(
                                    (phoneNumber, i) =>
                                      i === index
                                        ? e.target.value
                                        : phoneNumber,
                                  );
                                  formData.phoneNumbers = newPhones
                                    ? [...newPhones]
                                    : [''];

                                  return { ...formData };
                                });
                              }}
                              icon={
                                formData.phoneNumbers?.length !== 1 && (
                                  <IconTrash />
                                )
                              }
                              removeItem={() => {
                                setFormData({
                                  ...formData,
                                  phoneNumbers: formData.phoneNumbers?.filter(
                                    (item, id) => id !== index,
                                  ),
                                });
                              }}
                            />
                          ))}
                          {formData.phoneNumbers?.length &&
                            formData.phoneNumbers?.length < 3 && (
                              <button
                                className='add-block'
                                onClick={e => {
                                  e.preventDefault();
                                  setFormData({
                                    ...formData,
                                    phoneNumbers: [
                                      ...(formData.phoneNumbers ?? []),
                                      '',
                                    ],
                                  });
                                }}
                              >
                                <IconAdd />
                                <span>Adauga Telefon</span>
                              </button>
                            )}
                        </div>
                      </div>
                    </div>
                    <Input
                      name='phone'
                      type='tel'
                      inlineLabel='Telefon agenti'
                      labelText={true}
                      isClearable={
                        formData?.phoneEmployee !== '' ? true : false
                      }
                      removeItem={() =>
                        setFormData({ ...formData, phoneEmployee: '' })
                      }
                      value={formData?.phoneEmployee ?? ''}
                      error={organizationState.error?.phoneEmployee}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFormData({
                          ...formData,
                          phoneEmployee: e.target.value,
                        })
                      }
                    />
                    <div className='input-group input-group-remove-item'>
                      <Input
                        inlineLabel='Email'
                        labelText={true}
                        isClearable={formData?.email !== '' ? true : false}
                        removeItem={() =>
                          setFormData({ ...formData, email: '' })
                        }
                        value={formData.email ?? ''}
                        error={organizationState.error?.email}
                        onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setFormData({ ...formData, email: e.target.value });
                        }}
                      />
                      {organizationState.error?.email && (
                        <p className='error-message'>
                          {showError(organizationState.error?.email)}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className='form-line-section'>
                    <FormSelect
                      placeholder='Select'
                      labelText={true}
                      isClearable={true}
                      inlineLabel='Judet'
                      className={
                        organizationState.error?.countyId ? 'error' : ''
                      }
                      value={
                        formData?.countyId
                          ? getValue(county, formData?.countyId)
                          : null
                      }
                      options={parseValues(county) ?? []}
                      onChange={(e: SelectValue) => {
                        setCountyId(+e?.value);
                      }}
                    />
                    {organizationState.error?.countyId && (
                      <p className='error-message'>
                        {showError(organizationState.error?.countyId)}
                      </p>
                    )}
                    <FormSelect
                      placeholder='Select Options'
                      labelText={true}
                      isClearable={true}
                      inlineLabel='Oras'
                      value={
                        formData?.cityId
                          ? getValue(localities, formData?.cityId)
                          : null
                      }
                      options={parseValues(localities) ?? []}
                      onChange={(e: SelectValue) => {
                        setCityId(+e?.value);
                      }}
                    />
                    {organizationState.error?.cityId && (
                      <p className='error-message'>
                        {showError(organizationState.error?.cityId)}
                      </p>
                    )}
                    <MultiselectCheckboxes
                      onChange={(e: string[]) => handleChange('zones', e)}
                      labelText={true}
                      inlineLabel={'Zona'}
                      value={formData?.zones}
                      isClearable={true}
                      options={zona && parseValues(zona)}
                      error={organizationState.error?.zones}
                    />
                    {organizationState.error?.zones && (
                      <p className='error-message'>
                        {showError(organizationState.error?.zones)}
                      </p>
                    )}
                  </div>
                </div>
                <div className='form-line-section-half'>
                  <div className='form-line-section'>
                    <Input
                      name='cui'
                      type='text'
                      inlineLabel='CUI'
                      disabled={true}
                      labelText={true}
                      error={organizationState.error?.cui}
                      value={formData?.cui}
                    />

                    <Input
                      name='cif'
                      type='text'
                      inlineLabel='CIF'
                      disabled={true}
                      labelText={true}
                      error={organizationState.error?.cif}
                    />

                    <Input
                      name=''
                      type='text'
                      inlineLabel='Banca'
                      labelText={true}
                      isClearable={formData?.bank !== '' ? true : false}
                      removeItem={() => setFormData({ ...formData, bank: '' })}
                      error={organizationState.error?.bank}
                      value={formData?.bank}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFormData({ ...formData, bank: e.target.value })
                      }
                    />

                    <Input
                      name=''
                      type='text'
                      inlineLabel='Cont bancar'
                      labelText={true}
                      isClearable={formData?.bankAccount !== '' ? true : false}
                      removeItem={() =>
                        setFormData({ ...formData, bankAccount: '' })
                      }
                      error={organizationState.error?.bankAccount}
                      value={formData?.bankAccount}
                      onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setFormData({
                          ...formData,
                          bankAccount: e.target.value,
                        })
                      }
                    />

                    <MultiselectCheckboxes
                      onChange={(e: string[]) => handleChange('counties', e)}
                      labelText={true}
                      error={organizationState.error?.counties}
                      inlineLabel={'Judete activitate'}
                      value={formData?.counties}
                      isClearable={true}
                      options={counties && parseValues(counties)}
                    />
                    {organizationState.error?.counties && (
                      <p className='error-message'>
                        {showError(organizationState.error?.counties)}
                      </p>
                    )}
                  </div>

                  <div className='form-line-section'>
                    <div className='form-item-group'>
                      <label
                        className='form-item-group-label'
                        htmlFor='description'
                      >
                        Descriere Agentie
                      </label>
                      <div
                        className={`form-item-group-block  ${formData.description !== '' ? 'with-icon' : ''}`}
                      >
                        <textarea
                          name=''
                          id='description'
                          rows={4}
                          className={`form-item-control `}
                          value={formData?.description ?? ''}
                          onInput={(
                            e: React.ChangeEvent<HTMLTextAreaElement>,
                          ) =>
                            setFormData({
                              ...formData,
                              description: e.target.value,
                            })
                          }
                        ></textarea>
                        {formData.description !== '' && (
                          <div
                            className={`clear-icon`}
                            onClick={() => {
                              setFormData({ ...formData, description: '' });
                            }}
                          >
                            <div style={{ padding: '0px 5px' }}>
                              <IconClear />
                            </div>
                          </div>
                        )}
                      </div>
                      {organizationState.error?.description && (
                        <p className='error-message'>
                          {showError(organizationState.error?.description)}
                        </p>
                      )}
                    </div>
                    <div className='form-item-group'>
                      <label
                        className='form-item-group-label'
                        htmlFor='address'
                      >
                        Adresa
                      </label>
                      <div
                        className={`form-item-group-block ${formData.address !== '' ? 'with-icon' : ''}`}
                      >
                        <textarea
                          name=''
                          id='address'
                          rows={4}
                          className={`form-item-control `}
                          value={formData?.address ?? ''}
                          onInput={(
                            e: React.ChangeEvent<HTMLTextAreaElement>,
                          ) =>
                            setFormData({
                              ...formData,
                              address: e.target.value,
                            })
                          }
                        ></textarea>
                        {formData.address !== '' && (
                          <div
                            className={`clear-icon`}
                            onClick={() => {
                              setFormData({ ...formData, address: '' });
                            }}
                          >
                            <div style={{ padding: '0px 5px' }}>
                              <IconClear />
                            </div>
                          </div>
                        )}
                      </div>
                      {organizationState.error?.address && (
                        <p className='error-message'>
                          {showError(organizationState.error?.address)}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>{' '}
          <div className='btns-container'>
            <button className='reset-btn' onClick={() => cancel()}>
              Anuleaza
            </button>
            <button
              className='button-blue button-md'
              onClick={() => submitAgency()}
            >
              {organizationState.loading ? (
                <Spinner className='btn-blue-spinner' />
              ) : null}
              Salveaza
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default AddAgencySettings;

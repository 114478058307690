import React, { useState } from 'react';
import { getFilePath } from '../../helpers/useHelper';
import ImobilIcon from '../../assets/svg/imobiliareicon.svg';
import TitleIcon from '../../assets/svg/swipercardtitle.svg';
import './DropdownBrands.scss';

interface Props {}

interface Option {
  text: string;
  imagePath: string;
}

const DropdownBrands: React.FC<Props> = () => {
  const options: Option[] = [
    { text: 'Titrez.ro', imagePath: TitleIcon },
    { text: 'Imobilare.ro', imagePath: TitleIcon },
    { text: 'Imopedia.ro', imagePath: TitleIcon },
    { text: 'Imopedia.ro', imagePath: ImobilIcon },
    { text: 'Titrez.ro', imagePath: TitleIcon },
    { text: 'Titrez.ro', imagePath: ImobilIcon },
  ];
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);
  const [toggleSelect, setToggleSelect] = useState(false);

  const handleLiClick = (index: number) => {
    setSelectedOption(options[index]);
    setToggleSelect(false);
  };

  const toggleSelectedClass = () => {
    setToggleSelect(!toggleSelect);
  };

  return (
    <div className='dropdown-brands-container'>
      <div className={`content-select ${toggleSelect ? 'open' : ''}`}>
        <div className='header-select' onClick={toggleSelectedClass}>
          {selectedOption ? selectedOption.text : options[0].text}
          <img
            src={
              selectedOption ? selectedOption.imagePath : options[0].imagePath
            }
            alt=''
          />
        </div>
        <div className='options-select'>
          <ul>
            {options.map((option, index) => (
              <li key={index} onClick={() => handleLiClick(index)}>
                {option.text}
                <img src={option.imagePath} alt='' />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DropdownBrands;

import { unwrapResult } from '@reduxjs/toolkit';
import React, { useCallback, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ModalMobile from '../../components/AnouncesMobile/ModalMobile/ModalMobile';
import FilterCategory from '../../components/FilterCategory';
import FilterListHeader from '../../components/FilterListHeader';
import FilterListProperties from '../../components/FilterListProperties/FilterListProperties';
import FixedActions from '../../components/FixedActions/FixedActions';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import ModalConfirm from '../../components/ModalConfirm';
import Pagination from '../../components/Pagination';
import PropertyListItem from '../../components/PropertyListItem';
import SideNav from '../../components/SideNav';
import Spinner from '../../components/Spinner';
import { getCategories } from '../../redux/categories/categoriesThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  checkAll,
  toggleChecked,
} from '../../redux/properties/properties-slice';
import {
  arhiveProperties,
  getProperties,
  getPropertyFilters,
  getUsersProperties,
  removeProperties,
  restoreProperties,
} from '../../redux/properties/propertiesThunk';
import { haveUserPermission } from '../../helpers/useHelper';
import { Permissions } from '../../models/Permissions';
import { Property } from '../../models/Properties';
import IconPlus from '../../icons/IconPlus';
import './PropertyList.scss';

interface Props {
  title?: string;
}

const tabs = [
  {
    label: 'Proprietati Active',
    value: '1',
  },

  {
    label: 'Proprietati Arhivate',
    value: '4',
  },
  {
    label: 'Proprietati Sterse',
    value: '3',
  },

  {
    label: 'Toate Proprietatile',
    value: '1,4,0',
  },
];

const actionOptions = [
  {
    value: 'edit',
    label: 'Modifica',
  },
  {
    value: 'export',
    label: 'Exporta',
  },
  {
    value: 'arhive',
    label: 'Arhiveaza',
  },
  {
    value: 'remove',
    label: 'Sterge',
  },
  {
    value: 'restore',
    label: 'Restaureaza',
  },
];

const PropertyList: React.FC<Props> = ({ title }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { properties, totalCount, loading } = useAppSelector(
    state => state.properties,
  );
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);
  const [activeTab, setActiveTab] = useState(tabs[3].value);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(24);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [sort, setSort] = useState('');
  const [showCheckedItems, setShowCheckedItems] = useState<boolean>(false);
  const [isCheckedAll, setCheckedAll] = useState(false);
  const [activeCategory, setActiveCategory] = useState<number>();
  const [filtersForm, setFiltersForm] = useState();
  const [letterFilter, setLetterFilter] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [titleConfirmModal, setTitleConfirmModal] = useState('');
  const [bodyConfirmModal, setBodyConfirmModal] = useState('');
  const [havePermission, setHavePermission] = useState(true);
  const [isExpanded, setExpanded] = useState<number | null>(null);
  const [filterByLettersOnMobile, setFilterByLettersOnMobile] = useState(false);
  const [prevState, setPrevState] = useState({
    currentPage: 0,
    itemsPerPage: 24,
  });
  const optionsMock = useCallback(() => {
    switch (activeTab) {
      case '1':
      case '1,4,0':
        return actionOptions.slice(0, 4);
      case '3':
        return actionOptions.slice(4);
      case '4':
        return actionOptions.filter((_, index) => [0, 1, 3].includes(index));
    }
  }, [activeTab]);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1081);
  };

  const loadProperties = () => {
    const filters = {
      offset: currentPage + 1,
      limit: itemsPerPage,
      category: activeCategory,
      filters: filtersForm,
      sort: sort,
      letter: letterFilter,
      status: activeTab,
    };

    if (location.pathname === '/my-properties') {
      dispatch(getUsersProperties(filters));
    } else {
      dispatch(getProperties(filters));
    }

    closeModal();
    dispatch(checkAll(false));
    setCheckedAll(false);
  };

  useEffect(() => {
    dispatch(getCategories());

    if (location.pathname === '/my-properties') {
      setHavePermission(haveUserPermission(Permissions.PropertyMyList));
    } else if (location.pathname === '/agency-properties') {
      setHavePermission(haveUserPermission(Permissions.PropertyAgencyList));
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (totalCount) {
      const pageCount = Math.ceil(totalCount / itemsPerPage);

      setPageCount(pageCount);
    }
  }, [totalCount, itemsPerPage]);

  useEffect(() => {
    activeCategory === 0
      ? dispatch(getPropertyFilters())
      : dispatch(getPropertyFilters(activeCategory));

    if(filtersForm) {
      setFiltersForm(undefined);
    }
    else{
      loadProperties();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategory]);

  useEffect(() => {
    let newState = {
      currentPage: currentPage,
      itemsPerPage: itemsPerPage,
    };

    if (
      JSON.stringify(prevState.currentPage) !==
        JSON.stringify(newState.currentPage) ||
      JSON.stringify(prevState.itemsPerPage) !==
        JSON.stringify(newState.itemsPerPage)
    ) {
      loadProperties();
      setPrevState(newState);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, currentPage]);

  useEffect(() => {
    if (currentPage !== 0) {
      setCurrentPage(0);
    } else {
      loadProperties();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersForm, sort, letterFilter, activeTab]);

  const ModalAction = () => {
    setIsModalOpen(true);
    setIsAction(true);
    setIsSearch(false);
    setIsFilter(false);
  };

  const ModalFilter = () => {
    setIsModalOpen(true);
    setIsFilter(true);
    setIsAction(false);
    setIsSearch(false);
  };

  const ModalSearch = () => {
    setIsModalOpen(true);
    setIsSearch(true);
    setIsFilter(false);
    setIsAction(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCheckboxChange = (id?: number, all?: boolean): void => {
    if (id) {
      const item = properties.find(item => item.id === id);

      if (item && item.checked && isCheckedAll) {
        setCheckedAll(false);
      }

      dispatch(toggleChecked(id));
    }

    if (all) {
      dispatch(checkAll(true));
    }
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
  };

  const editItems = () => {
    if (properties.some(item => item.checked)) {
      navigate(`/properties/${properties.filter(item => item.checked)[0].id}`);
    } else {
      toast.error('Alegeti o proprietate!');
    }
  };

  const openConfirmModal = (titleAction: string, bodyAction: string) => {
    if (properties.some(item => item.checked)) {
      setTitleConfirmModal(`${titleAction} proprietati`);
      setBodyConfirmModal(
        `Esti sigur ca doresti sa ${bodyAction} proprietatile selectate?`,
      );
      setShowConfirmModal(true);
    } else {
      toast.error('Alegeti cel putin o proprietate!');
    }
  };

  const removeConfirmedItems = async () => {
    try {
      const result = await dispatch(
        removeProperties(properties
          .filter(item => item.checked).map(checked => checked.id).join(',')),
      );

      unwrapResult(result);

      toast.success('Proprietatile selectate au fost sterse!');
      loadProperties();
    } catch (err: any) {}
  };

  const arhiveConfirmedItems = async () => {
    try {
      const result = await dispatch(
        arhiveProperties(properties
          .filter(item => item.checked).map(checked => checked.id).join(',')),
      );

      unwrapResult(result);

      toast.success('Proprietatile selectate au fost arhivate!');
      loadProperties();
    } catch (err: any) {}
  };

  const restoreConfirmedItems = async () => {
    try {
      const result = await dispatch(
        restoreProperties(properties
          .filter(item => item.checked).map(checked => checked.id).join(',')),
      );

      unwrapResult(result);

      toast.success('Proprietatile selectate au fost restaurate!');
      loadProperties();
    } catch (err: any) {}
  };

  const exportItems = () => {
    toast.error('Acest functional temporar nu exista!');
  };

  const confirmActionWithSelectedItems = () => {
    if (titleConfirmModal === 'Stergere proprietati') {
      removeConfirmedItems();
    } else if (titleConfirmModal === 'Arhivare proprietati') {
      arhiveConfirmedItems();
    } else if (titleConfirmModal === 'Restaurare proprietati') {
      restoreConfirmedItems();
    }
  };

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        <Header
          title={title ?? 'Proprietati'}
          subtitle=''
          isbutton={havePermission}
          btnText='Adauga Proprietate'
          btnEvent={() => navigate('/add-property')}
        />

        {havePermission ? (
          <>
            {isMobile ? (
              <div>
                <select
                  className='select-tabs '
                  value={activeTab}
                  onChange={e => setActiveTab(e.target.value)}
                >
                  {tabs.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <Tabs
                id='ads-tabs'
                className='ads-form-tabs'
                activeKey={activeTab}
                onSelect={e => e && setActiveTab(e)}
              >
                {tabs.map(tab => (
                  <Tab
                    key={tab.value}
                    eventKey={tab.value}
                    title={tab.label}
                  ></Tab>
                ))}
              </Tabs>
            )}
            <FilterCategory
              activeCategory={activeCategory}
              setActiveCategory={setActiveCategory}
              filtersForm={filtersForm}
              setFiltersForm={setFiltersForm}
            />
            { loading ? (
              <div className='container'>
                <Spinner />
              </div>
            ) : (
              <>
                <FilterListHeader
                  pageCount={pageCount}
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  totalCount={totalCount}
                  count={properties?.length}
                  showCheckedItems={showCheckedItems}
                  checkedItemsLength={properties.filter(item => item.checked).length}
                  setShowCheckedItems={setShowCheckedItems}
                  setItemsPerPage={setItemsPerPage}
                  setCurrentPage={setCurrentPage}
                  handlePageClick={handlePageClick}
                  editItems={editItems}
                  removeItems={() => openConfirmModal('Stergere', 'stergi')}
                  arhiveItems={() => openConfirmModal('Arhivare', 'arhivezi')}
                  restoreItems={() =>
                    openConfirmModal('Restaurare', 'restaurezi')
                  }
                  exportItems={exportItems}
                  options={optionsMock()}
                />
                <FilterListProperties
                  setCheckedAll={(e: boolean) => {
                    setCheckedAll(e);

                    if (e) {
                      handleCheckboxChange(undefined, true);
                    }
                    else {
                      dispatch(checkAll(false));
                    }
                  }}
                  isCheckedAll={isCheckedAll}
                  sortProperty={setSort}
                  sort={sort}
                />
                <div className='list-items-container'>
                  {properties
                    .filter(item => !showCheckedItems || item.checked)
                    .map((item: Property) => (
                      <PropertyListItem
                        key={`${item.id} ${item.title}`}
                        item={item}
                        checked={item.checked}
                        handleCheckboxChange={handleCheckboxChange}
                        isExpanded={isExpanded}
                        setExpanded={setExpanded}
                      />
                    ))}
                  {!properties.some(item => !showCheckedItems || item.checked) && (
                    <p className='empty-text'>
                      {showCheckedItems
                        ? '0 Proprietati selectati'
                        : 'Cautarea ta a returnat 0 Rezultate'}
                    </p>
                  )}
                </div>
                {!showCheckedItems && pageCount > 1 && (
                  <Pagination
                    isSelectPage={false}
                    pageCount={pageCount}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    setItemsPerPage={setItemsPerPage}
                    handlePageClick={handlePageClick}
                  />
                )}
              </>
            )}
            <FixedActions
              disabledActions={properties.every(item => !item.checked)}
              ModalAction={ModalAction}
              ModalFilter={ModalFilter}
              ModalSearch={ModalSearch}
            />
            <div className='add-btn' onClick={() => navigate('/add-property')}>
              <IconPlus />
            </div>
            <ModalMobile
              isModalOpen={isModalOpen}
              closeModal={closeModal}
              isAction={isAction}
              isSearch={isSearch}
              isFilter={isFilter}
              activeCategory={activeCategory}
              filtersForm={filtersForm}
              setActiveCategory={setActiveCategory}
              setFiltersForm={setFiltersForm}
              removeItems={
                activeTab === '3'
                  ? undefined
                  : () => openConfirmModal('Stergere', 'stergi')
              }
              arhiveItems={
                activeTab === '4' || activeTab === '3'
                  ? undefined
                  : () => openConfirmModal('Arhivare', 'arhivezi')
              }
              restoreItems={
                activeTab === '3'
                  ? () => openConfirmModal('Restaurare', 'restaurezi')
                  : undefined
              }
              exportItems={activeTab === '3' ? undefined : () => exportItems()}
              editItems={activeTab === '3' ? undefined : () => editItems()}
              setCheckedAll={(e: boolean) => {
                setCheckedAll(e);

                if (e) {
                  handleCheckboxChange(undefined, true);
                } else {
                  dispatch(checkAll(false));
                }
              }}
              letterFilter={letterFilter}
              setLetterFilter={setLetterFilter}
              filterByLettersOnMobile={filterByLettersOnMobile}
              setFilterByLettersOnMobile={setFilterByLettersOnMobile}
            />
          </>
        ) : (
          <MissingAccess />
        )}
      </div>
      <ModalConfirm
        showModal={showConfirmModal}
        title={titleConfirmModal}
        body={bodyConfirmModal}
        onConfirmed={confirmActionWithSelectedItems}
        hideModal={() => setShowConfirmModal(false)}
      />
    </div>
  );
};

export default PropertyList;

import * as locale from 'date-fns/locale';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import DatePicker from 'react-datepicker';
import { useAppSelector } from '../../redux/hooks';
import useDebounce from '../../helpers/useDebounce';
import { getValue, parseValues } from '../../helpers/useHelper';
import { SelectValue } from '../../models/Properties';
import FormSelect from '../FormSelect';
import Input from '../Input/Input';
import ProprietySelect from '../ProprietySelect';
import Spinner from '../Spinner';
import './FilterActivityList.scss';

const statusOptions = [
  { value: 5, label: 'Rezolvata' },
  {
    value: 6,
    label: 'Viitoare',
  },
  {
    value: 7,
    label: 'Depasita',
  },
];

interface Props {
  isCheckedAll?: boolean;
  setCheckedAll?: (all: boolean) => void;
  sortActivity?: (field: string) => void;
  sort?: string;
  filtersForm: any;
  setFiltersForm: (e: any) => void;
  closeModal?: () => void;
}

const FilterActivityList: React.FC<Props> = ({
  isCheckedAll,
  setCheckedAll,
  sort,
  sortActivity,
  filtersForm,
  setFiltersForm,
  closeModal,
}) => {
  const { activitiesTypes, activitiesLoading } = useAppSelector(state => state.activities);
  const { contacts } = useAppSelector(state => state.contacts);
  const { properties } = useAppSelector(state => state.properties);
  const { requests } = useAppSelector(state => state.requests).requestsListState;

  const [formData, setFormData] = useState<any>(filtersForm);
  const [showCalendar, setShowCalendar] = useState(false);
  const [startDate, setStartDate] = useState<Date | null>(formData.dateFrom ? new Date(formData.dateFrom) : null);
  const [finalDate, setFinalDate] = useState<Date | null>(formData.dateTo ? new Date(formData.dateTo) : null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);

  const debouncedTitleSearch = useDebounce(formData?.titleSearch ?? '', 300);
  const myRef = useRef<any>();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('resize', handleResize);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !showCalendar &&
      !isMobile &&
      (filtersForm.dateFrom !== formData.dateFrom || filtersForm.dateTo !== formData.dateTo)
    ) {
      setFiltersForm(formData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCalendar]);

  useEffect(() => {
    if (!isMobile) {
      setFiltersForm({ ...formData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTitleSearch]);

  useEffect(() => {
    if (formData) {
      setStartDate(formData.dateFrom ? new Date(formData.dateFrom) : null);
      setFinalDate(formData.dateTo ? new Date(formData.dateTo) : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1081);
  };

  const handleClickOutside = (e: any) => {
    if (myRef.current && !myRef.current?.contains(e.target)) {
      setShowCalendar(false);
    }
  };

  const handleSelect = (ranges: any) => {
    setFormData({
      ...formData,
      dateFrom: ranges.selection?.startDate
        ? moment(ranges.selection?.startDate).format('YYYY-MM-DD') + ' 00:00:00'
        : null,
      dateTo: ranges.selection?.endDate ? moment(ranges.selection?.endDate).format('YYYY-MM-DD') + ' 23:59:59' : null,
    });
  };

  const submitFilters = () => {
    setFiltersForm(formData);
    closeModal && closeModal();
  };

  const updateFilters = (fieldName: string, fieldValue: any) => {
    setFormData({ ...formData, [fieldName]: fieldValue });

    if (!isMobile) {
      setFiltersForm({ ...formData, [fieldName]: fieldValue });
    }
  };

  const resetFilters = () => {
    setFormData({ offset: 1 });
    setFiltersForm({ offset: 1 });
    closeModal && closeModal();
  };

  return (
    <div className={`filter-list-container filter-activity-list ${activitiesLoading ? 'disabled' : ''}`}>
      <form>
        <Form.Group id='formGridCheckbox'>
          <Form.Check
            type='checkbox'
            checked={isCheckedAll}
            onChange={() => setCheckedAll && setCheckedAll(!isCheckedAll)}
          />
          <div className='mobile-select-all mobile-text'>Selectati toate activitatile</div>
        </Form.Group>
        <div
          className={`filter-item arrows-label-icon ${sort === 'status' ? 'active-bottom' : ''} ${sort === '-status' ? 'active-top' : ''}`}>
          <label
            onClick={() => {
              if (sortActivity)
                sort === 'status'
                  ? sortActivity('-status')
                  : sort === '-status'
                    ? sortActivity('')
                    : sortActivity('status');
            }}>
            Status
          </label>

          <FormSelect
            labelText={false}
            options={statusOptions}
            value={statusOptions.find(option => option.value === formData?.status) ?? null}
            onChange={(e: SelectValue) => updateFilters('status', e?.value ?? null)}
            placeholder={'Select'}
            isSearchable={false}
            isClearable={true}
          />
        </div>
        <div
          className={`filter-item arrows-label-icon ${sort === 'typeTitle' ? 'active-bottom' : ''} ${sort === '-typeTitle' ? 'active-top' : ''}`}>
          <label
            onClick={() => {
              if (sortActivity)
                sort === 'typeTitle'
                  ? sortActivity('-typeTitle')
                  : sort === '-typeTitle'
                    ? sortActivity('')
                    : sortActivity('typeTitle');
            }}>
            Tip
          </label>
          <FormSelect
            value={
              parseValues(activitiesTypes)?.find(
                item => +item?.value === getValue(activitiesTypes, formData?.type)?.value,
              ) ?? null
            }
            isClearable={true}
            placeholder={'Select'}
            options={parseValues(activitiesTypes)}
            onChange={(e: SelectValue) => {
              updateFilters('type', e ? Number(e.value) : null);
            }}
          />
        </div>
        <div className='filter-item'>
          <Input
            inlineLabel='Titlu'
            placeholder='Search'
            labelText={true}
            type='search'
            value={formData?.titleSearch ?? ''}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFormData({ ...formData, titleSearch: e.target.value })
            }
            handleKeyDown={(e: any) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
          />
        </div>
        <div className='filter-item search-container'>
          <FormSelect
            type='text'
            placeholder='Search'
            id='ads-more'
            isSearchable={true}
            inlineLabel={'Contacte Asociate'}
            labelText={true}
            options={parseValues(contacts)}
            isClearable={true}
            searchIndicator={true}
            value={formData.contact ? getValue(contacts, formData.contact) : null}
            onChange={(e: SelectValue) => {
              updateFilters('contact', e ? Number(e.value) : null);
            }}
          />
        </div>
        <div className='filter-item search-container'>
          <ProprietySelect
            type='text'
            placeholder='Search'
            id='ads-more'
            inlineLabel={'Prop. Asociate'}
            labelText={true}
            isClearable={true}
            searchIndicator={true}
            searchByCharacter={true}
            onChange={(e: SelectValue) => {
              updateFilters('properties', e ? Number(e.value) : null);
            }}
            value={() => {
              const item = properties?.find((item: any) => item?.id === Number(formData.properties));

              if (item) {
                return {
                  label: item.id,
                  value: formData.properties,
                };
              } else {
                return null;
              }
            }}
          />
        </div>
        <div className='filter-item search-container'>
          <label>Solicitări Asociate</label>
          <FormSelect
            type='text'
            placeholder='Search'
            id='ads-more'
            isSearchable={true}
            labelText={false}
            options={parseValues(requests)}
            searchIndicator={true}
            isClearable={true}
            value={formData.requests ? getValue(requests, formData.requests) : null}
            onChange={(e: SelectValue) => {
              updateFilters('requests', e ? Number(e.value) : null);
            }}
          />
        </div>
        <div className='filter-item arrows-label-icon'>
          <div className='date-picker'>
            <label
              onClick={() => {
                if (sortActivity)
                  sort === 'dateStart'
                    ? sortActivity('-dateStart')
                    : sort === '-dateStart'
                      ? sortActivity('')
                      : sortActivity('dateStart');
              }}>
              Data
            </label>
            <div className='display-flex flex-column date-column'>
              {showCalendar && (
                <div className='range-calendar' ref={myRef}>
                  <DateRangePicker
                    ranges={[
                      {
                        startDate: startDate ?? undefined,
                        endDate: finalDate ?? undefined,
                        key: 'selection',
                      },
                    ]}
                    locale={locale.enUS}
                    onChange={e => {
                      handleSelect(e);
                    }}
                  />
                </div>
              )}
              <DatePicker
                placeholderText={
                  formData.dateFrom && formData.dateTo
                    ? `${moment(formData.dateFrom).format('YYYY-MM-DD')} - ${moment(formData.dateTo).format('YYYY-MM-DD')}`
                    : 'De la/Pana la'
                }
                selected={null}
                onCalendarOpen={() => setShowCalendar(true)}
                onChange={() => {}}
              />
            </div>
          </div>
        </div>
        <div className='filter-item'>
          <button
            className='filter-btn btn-blue'
            onClick={e => {
              resetFilters();
              e.preventDefault();
            }}>
            Reseteaza
          </button>
        </div>
      </form>{' '}
      <div className='bottom-filter-section'>
        <div className='form-line-section'></div>
        <div className='form-btns-section'>
          <button
            className='reset-btn'
            onClick={e => {
              resetFilters();
              e.preventDefault();
            }}>
            Reseteaza{' '}
          </button>
          <button
            className='filter-btn btn-blue'
            onClick={e => {
              submitFilters();
              e.preventDefault();
            }}>
            {activitiesLoading && <Spinner className='btn-blue-spinner' />}
            Filtreaza
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterActivityList;

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  getActivities,
  removeActivities,
} from '../../redux/activities/activitiesThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getFilePath } from '../../helpers/useHelper';
import { ActivityItem } from '../../models/Activities';
import CloseIcon from '../../assets/svg/closemodal.svg';
import IconPencil from '../../icons/IconPencil';
import IconTrash from '../../icons/IconTrash';
import './ActivityInfoModal.scss';

interface Props {
  activityId: number | null;
  isModalOpen: boolean;
  closeModal: () => void;
}

const ActivityAddModal: React.FC<Props> = ({
  activityId,
  isModalOpen,
  closeModal,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { activities } = useAppSelector(state => state.activities);
  const [activity, setActivity] = useState<ActivityItem | null>(null);
  useEffect(() => {
    setActivity(
      activities.find(item => item.id === Number(activityId)) ?? null,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityId]);
  const removeActivity = () => {
    dispatch(removeActivities(String(activityId))).then(res => {
      if (res.type === 'auth/removeActivities/fulfilled') {
        toast.success('Activitatea a fost stearsa cu succes!');
        closeModal();
        dispatch(getActivities({}));
      }
    });
  };

  return (
    <>
      <Modal
        show={isModalOpen}
        onHide={closeModal}
        animation={true}
        className={'mobile-modal activities-modal'}
      >
        <Modal.Header>
          <div className='close-btn-modal' onClick={closeModal}>
            <img src={CloseIcon} alt='CloseIcon' />
          </div>
          <span>{activity?.typeTitle}</span>
        </Modal.Header>
        <Modal.Body>
          <div className='add-activity-modal'>
            <div className='flex-range'>
              {activity?.activityTypeIconPath ? (
                <img
                  className='img-block'
                  src={getFilePath(activity?.activityTypeIconPath)}
                  alt=''
                />
              ) : null}
              <div className='column-item'>
                <div className='column-row'>
                  <span className='label'>Agent:</span>
                  <span className='value'>
                    {activity?.employeeProfileFullName}
                  </span>
                </div>
                <div className='column-row'>
                  <span className='label'>Inceput:</span>
                  <span className='value'>
                    {activity?.allDay ? (
                      <>{moment(activity?.dateStart).format('DD-MM-YYYY')}</>
                    ) : (
                      <>
                        {moment(activity?.dateStart).format('DD-MM-YYYY HH:mm')}
                      </>
                    )}
                  </span>
                </div>
                <div className='column-row'>
                  <span className='label'>Sfarsit:</span>
                  <span className='value'>
                    {activity?.allDay ? (
                      <>{moment(activity?.dateEnd).format('DD-MM-YYYY')}</>
                    ) : (
                      <>
                        {moment(activity?.dateEnd).format('DD-MM-YYYY HH:mm')}
                      </>
                    )}
                  </span>
                </div>
              </div>
            </div>
            {activity?.title && (
              <div className='column-row'>
                <span>{activity?.title}</span>
              </div>
            )}
            {activity?.details && (
              <div className='column-row'>
                <span>{activity?.details}</span>
              </div>
            )}
            {activity?.employeeEmail && (
              <div className='column-row'>
                <span className='label'>Email</span>
                <span> {activity?.employeeEmail}</span>
              </div>
            )}
            {activity?.contacts.map(contact => (
              <div className='column-item'>
                <div className='column-row'>
                  <span>Contact</span>
                  <a href={'/contacts/' + contact?.id}>
                    {contact?.name + ' ' + contact?.surname}
                  </a>
                </div>
                <div className='column-row'>
                  <span>{contact?.internationalPhoneNumber}</span>
                </div>
                <div className='column-row'>
                  <span>{contact?.emails[0]}</span>
                </div>
              </div>
            ))}
            <div className='btns-container'>
              <button
                className='button-square-md'
                aria-label=''
                onClick={() => navigate('/activities/' + activityId)}
              >
                <IconPencil />
              </button>
              <button
                className='button-square-md'
                aria-label=''
                onClick={() => removeActivity()}
              >
                <IconTrash />
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ActivityAddModal;

import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import { useAppDispatch } from '../../redux/hooks';
import { deleteUser } from '../../redux/users/usersThunk';
import { getFilePath, getLoggedUserId } from '../../helpers/useHelper';
import { Agent } from '../../models/Users';
import ProfileImg from '../../assets/img/avatar.png';
import IconEnvelope from '../../icons/IconEnvelope';
import IconPencil from '../../icons/IconPencil';
import IconPhone from '../../icons/IconPhone';
import IconTrash from '../../icons/IconTrash';
import ModalConfirm from '../ModalConfirm';
import './AgentsListItem.scss';

interface Props {
  agent: Agent;
  onDeletedUser: any;
  handleCheckboxChange: (id: number) => void;
}

const AgentsListItem: React.FC<Props> = ({
  agent,
  onDeletedUser,
  handleCheckboxChange,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onDeleteAgent = () => {
    setShowDeleteModal(false);
    dispatch(deleteUser(agent.id)).then(() => {
      onDeletedUser();
    });
  };

  const editAgent = () => {
    navigate(`/edit-agent/${agent.id}`);
  };

  return (
    <div
      className={`announces-list-item agent-item ${agent.checked ? ' active' : ''}`}
    >
      <div className='mobile-lines'>
        <div className='line-border'></div>
        <div className='checkbox-container'>
          <Form.Group id='formGridCheckbox'>
            <Form.Check
              type='checkbox'
              checked={agent.checked}
              readOnly
              onClick={() => handleCheckboxChange(agent.id)}
            />
          </Form.Group>
        </div>
        <div className='line-border'></div>
      </div>
      <div className='list-item-content'>
        <div className='column-item'>
          <div className='contact-details'>
            <div className='info-img' onClick={editAgent}>
              <img
                src={
                  agent?.avatarPath ? getFilePath(agent.avatarPath) : ProfileImg
                }
                alt='ProfileIcon'
              />
            </div>
            <div className='info-contact-details' onClick={editAgent}>
              {agent?.fullName && <div className='name'>{agent?.fullName}</div>}
              <div className='flex-details'>
                {agent?.phoneNumbers?.length > 0 &&
                  agent.phoneNumbers
                    .filter(phone => phone)
                    .map(phone => (
                      <a href='tel:' aria-label='' className='number'>
                        <IconPhone />
                        {phone}
                      </a>
                    ))}
                {agent?.email && (
                  <a href='mailto:' aria-label='' className='mail'>
                    <IconEnvelope />
                    {agent?.email}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Proprietati</div>
          <div className='info-number'>{agent?.propertiesCount}</div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Solicitări</div>
          <div className='info-number'>{agent?.requestsCount}</div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Oferte</div>
          <div className='info-number'>
            {agent?.offersCount !== 0 ? agent.offersCount : '-'}
          </div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Contacte</div>
          <div className='info-number'>{agent?.contactsCount}</div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Status</div>
          <div
            className={`label-info label-info-${agent.status === 1 ? 'a' : 'ina'}ctive`}
          >
            {agent?.status === 1 ? 'A' : 'Ina'}ctiv
          </div>
        </div>
        <div className='line-border'></div>
        <div className='info-icons'>
          <button
            className='button-square-md'
            data-tooltip-id='edit'
            data-tooltip-content='Modifica agent'
            aria-label=''
            onClick={editAgent}
          >
            <IconPencil />
          </button>
          <Tooltip id='edit' />

          <button
            className='button-square-md'
            aria-label=''
            data-tooltip-id='remove'
            data-tooltip-content='Sterge agent'
            onClick={() => {
              if (getLoggedUserId() === agent.id.toString()) {
                toast.error('Nu poate fi sters agentul cu care sunteti logat!');
              } else {
                setShowDeleteModal(true);
              }
            }}
          >
            <IconTrash />
          </button>
          <Tooltip id='remove' />
        </div>
      </div>
      <ModalConfirm
        showModal={showDeleteModal}
        title='Stergere agent'
        body='Esti sigur ca doresti sa stergi agentul?'
        onConfirmed={onDeleteAgent}
        hideModal={() => setShowDeleteModal(false)}
      />
    </div>
  );
};

export default AgentsListItem;

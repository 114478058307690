import React from 'react';
import Modal from 'react-bootstrap/Modal';
import CloseIcon from '../../assets/svg/closemodal.svg';
import ActionsMobile from '../ActionsMobile';
import FilterListTags from '../FilterListTags/FilterListTags';
import SearchMobile from '../SearchMobile';
import './AnouncesTagsModal.scss';

interface Props {
  isModalOpen: boolean;
  isAction: boolean;
  isSearch: boolean;
  isFilter: boolean;
  closeModal: () => void;
}

const AnouncesTagsModal: React.FC<Props> = ({
  isModalOpen,
  closeModal,
  isAction,
  isSearch,
  isFilter,
}) => {
  return (
    <>
      <Modal
        show={isModalOpen}
        onHide={closeModal}
        animation={true}
        className={
          isFilter || isAction ? 'mobile-modal filter-overflow' : 'mobile-modal'
        }
      >
        <Modal.Body>
          <div className='close-btn-modal' onClick={closeModal}>
            <img src={CloseIcon} alt='CloseIcon' />
          </div>
          {isSearch && <SearchMobile />}
          {isAction && <ActionsMobile />}
          {isFilter && <FilterListTags closeModal={closeModal} />}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AnouncesTagsModal;

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import DatePicker from 'react-datepicker';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  clearContact,
  clearContacts,
} from '../../redux/contacts/contacts-slice';
import {
  createContact,
  editContact,
  getContacts,
} from '../../redux/contacts/contactsThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { uploadImage } from '../../redux/media/mediaThunk';
import useHelper, {
  getFilePath,
  getValue,
  parseContact,
  parseValues,
  showError,
} from '../../helpers/useHelper';
import { ContactDetailsForm } from '../../models/Contacts';
import { SelectValue } from '../../models/Properties';
import DeleteIcon from '../../assets/svg/deletebtn.svg';
import editIcon from '../../assets/svg/editbtn.svg';
import IconAdd from '../../icons/IconAdd';
import IconTrash from '../../icons/IconTrash';
import IconUploadPhoto from '../../icons/IconUploadPhoto';
import ContactDuplicateModal from '../ContactDuplicateModal';
import FormSelect from '../FormSelect';
import Input from '../Input/Input';
import MultiselectCheckboxes from '../MultiselectCheckboxes';
import Spinner from '../Spinner';
import './AddContactForm.scss';

interface Props {
  editView: boolean;
}

const AddContactForm: React.FC<Props> = ({ editView }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { diplayErrorMessages } = useHelper();
  const { isFromAgencyList } = useLocation().state || {};
  const { users } = useAppSelector(state => state.users);
  const { companies } = useAppSelector(state => state.companies);
  const { leadSources, error, loadingSave, contact, contactsTypes } =
    useAppSelector(state => state.contacts);
  const [birthday, setBirthday] = useState<Date | null>();
  const [formData, setFormData] = useState<ContactDetailsForm>({
    phoneNumbers: [{ phone: '', needVerification: 1 }],
    emails: [''],
    socialMedias: [''],
  });
  const [formDataErrors, setFormDataErrors] = useState<any>({});
  const [profileImage, setProfileImage] = useState<string>('');
  const [modalType, setModalType] = useState<
    'exists' | 'assignedContact' | 'alocated'
  >('exists');
  const [assignedContact, setAssignedContact] = useState<string>('');

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (contact && editView) {
      setFormData(parseContact(contact));
      setProfileImage(
        contact?.avatar?.path ? getFilePath(contact?.avatar?.path) : '',
      );
      getPossibleContacts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact, editView]);

  useEffect(() => {
    setBirthday(formData.birthday ? new Date(formData.birthday) : null);
  }, [formData.birthday]);

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      dispatch(
        uploadImage({
          file: file,
          setProgress: (e: number) => {},
        }),
      ).then(res => {
        if (res.payload?.id) {
          setProfileImage(getFilePath(res.payload.filePath));
          setFormData((formData: any) => {
            formData.avatar = res.payload.id;
            return { ...formData };
          });
        }
      });
    }
  };

  const deleteProfileImage = () => {
    setProfileImage('');
    setFormData((formData: any) => {
      formData.avatar = 0;

      return { ...formData };
    });
  };

  const submitForm = () => {
    formData && contact
      ? dispatch(editContact(formData)).then(res => processResponse(res))
      : dispatch(createContact(formData)).then(res => processResponse(res));
  };

  const processResponse = (res: any) => {
    if (res.payload?.id) {
      toast.success('Contactul a fost salvat cu success!');
      dispatch(clearContact());
      dispatch(clearContacts());
      setFormData({
        phoneNumbers: [
          { phone: '', needVerification: 1 },
          { phone: '', needVerification: 1 },
        ],
        emails: [''],
        socialMedias: [''],
      });

      if (isFromAgencyList) {
        navigate('/agency-contacts');
      } else {
        navigate('/my-contacts');
      }
    } else {
      setIsModalOpen(true);
      setAssignedContact('Thomas ');
      setModalType('alocated');
      setFormDataErrors(res.payload);
      diplayErrorMessages(res.payload);
    }
  };

  const resetForm = () => {
    formData &&
      setFormData({
        phoneNumbers: [{ phone: '', needVerification: 1 }],
        emails: [''],
        socialMedias: [''],
      });
    setProfileImage('');
  };

  const getPossibleContacts = () => {
    if (
      (formData.name?.length && formData.name?.length > 0) ||
      (formData.surname?.length && formData.surname?.length > 0) ||
      formData.emails[0]?.length > 0
    )
      dispatch(
        getContacts({
          fullName:
            (formData.name ?? '') +
            (formData.surname
              ? (formData.name ? ' ' : '') + formData.surname
              : ''),
          email: formData.emails[0],
        }),
      );
  };

  return (
    <div className='add-contact-container'>
      {/* <div className='back-btn mobile-text' onClick={() => navigate(-1)}>
        <IconArrowLeft />
      </div> */}
      <div className='form-container'>
        <div className='img-upload-block'>
          <div className='upload-container'>
            <label htmlFor='file-upload' className='custom-file-upload'>
              {profileImage.length > 0 ? (
                <img src={profileImage} alt='' />
              ) : (
                <IconUploadPhoto />
              )}
            </label>
            <input
              id='file-upload'
              type='file'
              accept='image/*'
              onChange={handleImageChange}
            />
            {profileImage?.length > 0 && (
              <>
                <button
                  className='edit-btn'
                  aria-label='Editeaza'
                  onClick={() =>
                    document.getElementById('file-upload')?.click()
                  }
                >
                  <img src={editIcon} alt='editIcon' />
                </button>
                <button
                  className='delete-btn'
                  aria-label='Sterge'
                  onClick={deleteProfileImage}
                >
                  <img src={DeleteIcon} alt='DeleteIcon' />
                </button>
              </>
            )}{' '}
          </div>
          <div className='radion-block'>
            <Form.Check
              reverse
              type='switch'
              id='custom-switch'
              label='Status'
              checked={formData.status === 1}
              onChange={e =>
                setFormData({
                  ...formData,
                  status: e.target.checked ? 1 : 0,
                })
              }
            />
          </div>
        </div>
        <div className='form-block'>
          <h4 className='title'>Informatii</h4>
          <form>
            <div className='form-line-section'>
              <div className='form-item'>
                <Input
                  labelText={true}
                  value={formData?.name ?? ''}
                  error={formDataErrors?.name}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                  inlineLabel='Nume'
                  onBlur={() => getPossibleContacts()}
                />
              </div>
              <div className='form-item'>
                <Input
                  labelText={true}
                  value={formData?.surname ?? ''}
                  error={formDataErrors?.surname}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFormData({ ...formData, surname: e.target.value })
                  }
                  inlineLabel='Prenume'
                  onBlur={() => getPossibleContacts()}
                />
              </div>
              <div className='input-group'>
                <label className='form-item-group-label'>Telefon</label>
                {formData?.phoneNumbers?.map((phoneNumber, index) => (
                  <Input
                    labelText={false}
                    value={phoneNumber.phone ?? ''}
                    error={formDataErrors?.phoneNumbers}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFormData((formData: ContactDetailsForm) => {
                        const newPhones = formData.phoneNumbers.map(
                          (phoneNumber, i) =>
                            i === index
                              ? { ...phoneNumber, phone: e.target.value }
                              : phoneNumber,
                        );
                        formData.phoneNumbers = [...newPhones];

                        return { ...formData };
                      });
                    }}
                    icon={formData.phoneNumbers.length !== 1 && <IconTrash />}
                    removeItem={() => {
                      setFormData({
                        ...formData,
                        phoneNumbers: formData.phoneNumbers.filter(
                          (item, id) => index !== id,
                        ),
                      });
                    }}
                  />
                ))}
                {formData.phoneNumbers.length < 3 && (
                  <button
                    className='add-block'
                    onClick={e => {
                      e.preventDefault();
                      setFormData({
                        ...formData,
                        phoneNumbers: [
                          ...formData.phoneNumbers,
                          {
                            phone: '',
                            needVerification: 1,
                          },
                        ],
                      });
                    }}
                  >
                    <IconAdd />
                    <span>Adauga Telefon</span>
                  </button>
                )}
              </div>
              <div className='input-group'>
                <label className='form-item-group-label'>Email</label>
                {formData?.emails?.map((email, index) => (
                  <Input
                    labelText={false}
                    value={email ?? ''}
                    error={formDataErrors?.emails}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setFormData((formData: ContactDetailsForm) => {
                        const newEmails = formData.emails.map((email, i) =>
                          i === index ? e.target.value : email,
                        );
                        formData.emails = [...newEmails];

                        return { ...formData };
                      });
                    }}
                    icon={formData.emails.length !== 1 && <IconTrash />}
                    removeItem={() => {
                      setFormData({
                        ...formData,
                        emails: formData.emails.filter(
                          (item, id) => index !== id,
                        ),
                      });
                    }}
                    onBlur={() => getPossibleContacts()}
                  />
                ))}
                {formData.emails.length < 3 && (
                  <button
                    className='add-block'
                    onClick={e => {
                      e.preventDefault();
                      setFormData({
                        ...formData,
                        emails: [...formData.emails, ''],
                      });
                    }}
                  >
                    <IconAdd />
                    <span>Adauga Email</span>
                  </button>
                )}
              </div>
              <div className='form-item'>
                <Input
                  labelText={true}
                  value={formData?.internationalPhoneNumber ?? ''}
                  error={formDataErrors?.internationalPhoneNumber}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFormData({
                      ...formData,
                      internationalPhoneNumber: e.target.value,
                    })
                  }
                  inlineLabel='Telefon international'
                />
              </div>
              <div className='form-item'>
                <Input
                  labelText={true}
                  value={formData?.address ?? ''}
                  error={formDataErrors?.address}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFormData({ ...formData, address: e.target.value })
                  }
                  inlineLabel='Adresa'
                />
                {error?.address && (
                  <p className='error-message'>{showError(error.address)}</p>
                )}
              </div>
            </div>
            <div className='form-line-section'>
              <div className='form-item form-item-group'>
                <label className='form-item-group-label'>Data nasterii</label>
                <DatePicker
                  selected={birthday}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode='select'
                  className={error?.birthday ? 'error' : ''}
                  onChange={date =>
                    setFormData({
                      ...formData,
                      birthday: date
                        ? moment(date).format('YYYY-MM-DD') + ' 00:00:00'
                        : '',
                    })
                  }
                />
              </div>
              <div className='form-item'>
                <Input
                  labelText={true}
                  value={formData?.cnp ?? ''}
                  error={formDataErrors?.cnp}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFormData({ ...formData, cnp: e.target.value })
                  }
                  inlineLabel='CNP'
                />
                {error?.cnp && (
                  <p className='error-message'>{showError(error.cnp)}</p>
                )}
              </div>
              <div className='input-group'>
                <label className='form-item-group-label'>Social Media</label>
                {formData?.socialMedias?.map((socialMedia, index) => (
                  <Input
                    labelText={false}
                    value={socialMedia ?? ''}
                    placeholder='(Facebook, Twitter etc.)'
                    error={formDataErrors?.socialMedias}
                    onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                      let newSocialMedia = formData?.socialMedias ?? [];
                      setFormData({
                        ...formData,
                        socialMedias: newSocialMedia.map((social, id) => {
                          if (id === index) return e.target.value;
                          else return social;
                        }),
                      });
                    }}
                    icon={formData.socialMedias?.length !== 1 && <IconTrash />}
                    removeItem={() => {
                      setFormData({
                        ...formData,
                        socialMedias: formData.socialMedias?.filter(
                          (item, id) => index !== id,
                        ),
                      });
                    }}
                  />
                ))}
                {(!formData.socialMedias ||
                  (formData.socialMedias &&
                    formData.socialMedias.length < 3)) && (
                  <button
                    className='add-block'
                    onClick={e => {
                      e.preventDefault();
                      setFormData({
                        ...formData,
                        socialMedias: formData.socialMedias
                          ? [...formData.socialMedias, '']
                          : [''],
                      });
                    }}
                  >
                    <IconAdd />
                    <span>Adauga Social Media</span>
                  </button>
                )}
              </div>
              <div className='form-item'>
                <FormSelect
                  labelText={true}
                  placeholder='Select Option'
                  inlineLabel={'Companie'}
                  isSearchable={true}
                  isClearable={true}
                  error={formDataErrors?.companyId}
                  value={
                    formData.companyId
                      ? getValue(companies, formData.companyId)
                      : null
                  }
                  options={companies.length > 0 ? parseValues(companies) : []}
                  onChange={(e: SelectValue) =>
                    setFormData({
                      ...formData,
                      companyId: e ? Number(e.value) : undefined,
                    })
                  }
                />
                {error?.companyId && (
                  <p className='error-message'>{showError(error.companyId)}</p>
                )}
              </div>
              <div className='form-item'>
                <Input
                  labelText={true}
                  value={formData?.jobTitle ?? ''}
                  error={formDataErrors?.jobTitle}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setFormData({ ...formData, jobTitle: e.target.value })
                  }
                  inlineLabel='Functie'
                />
                {error?.jobTitle && (
                  <p className='error-message'>{showError(error.jobTitle)}</p>
                )}
              </div>
            </div>
            <div className='form-line-section'>
              <div className='form-item'>
                <MultiselectCheckboxes
                  onChange={(e: string[]) => {
                    setFormData({
                      ...formData,
                      types: e,
                    });
                  }}
                  labelText={true}
                  inlineLabel={'Tip Contact'}
                  value={
                    formData?.types
                      ? formData.types.map(type => String(type))
                      : null
                  }
                  options={parseValues(contactsTypes) ?? []}
                />
              </div>
              <div className='form-item'>
                <FormSelect
                  labelText={true}
                  placeholder='Select Option'
                  inlineLabel={'Sursa Lead'}
                  isSearchable={true}
                  isClearable={true}
                  error={formDataErrors?.leadSourceId}
                  value={
                    formData.leadSourceId
                      ? getValue(leadSources, formData.leadSourceId)
                      : null
                  }
                  options={
                    leadSources.length > 0 ? parseValues(leadSources) : []
                  }
                  onChange={(e: SelectValue) =>
                    setFormData({
                      ...formData,
                      leadSourceId: e ? Number(e.value) : undefined,
                    })
                  }
                />
              </div>
              <div className='form-item'>
                <MultiselectCheckboxes
                  onChange={(e: number[]) => {
                    setFormData({
                      ...formData,
                      associatedEmployee: e,
                    });
                  }}
                  labelText={true}
                  inlineLabel={'Asociaza Agentii'}
                  value={
                    formData?.associatedEmployee
                      ? formData.associatedEmployee.map(employee =>
                          String(employee),
                        )
                      : []
                  }
                  options={parseValues(users) ?? []}
                />
              </div>
              <div className='form-item-group'>
                <label className='form-item-group-label' htmlFor=''>
                  Nota
                </label>
                <div className={`form-item-group-block`}>
                  <textarea
                    name=''
                    id=''
                    className={`form-item-control `}
                    value={formData.notes ?? ''}
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                      setFormData({
                        ...formData,
                        notes: e.target.value,
                      })
                    }
                  ></textarea>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className='btns-container'>
        <button
          className='button-blue button-md'
          onClick={e => {
            e.preventDefault();
            submitForm();
          }}
        >
          {loadingSave ? <Spinner className='btn-blue-spinner' /> : null}
          Salveaza
        </button>
        <button
          className='reset-btn'
          onClick={e => {
            e.preventDefault();
            resetForm();
          }}
        >
          Reseteaza
        </button>
      </div>
      <ContactDuplicateModal
        modalType={modalType}
        contact={formData}
        assignedContact={assignedContact}
        isModalOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
      />
    </div>
  );
};

export default AddContactForm;

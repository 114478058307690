import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AnnouncesCountPopup from '../../components/AnnouncesCountPopup';
import AnnouncesListItem from '../../components/AnnouncesListItem';
import AnouncesListCard from '../../components/AnouncesMobile/AnouncesListCard/AnouncesListCard';
import ModalMobile from '../../components/AnouncesMobile/ModalMobile/ModalMobile';
import FilterCategory from '../../components/FilterCategory';
import FilterList from '../../components/FilterList/FilterList';
import FilterListHeader from '../../components/FilterListHeader';
import FixedActions from '../../components/FixedActions/FixedActions';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import ModalAdLabels from '../../components/ModalAdLabels';
import ModalConfirm from '../../components/ModalConfirm';
import Pagination from '../../components/Pagination';
import SideNav from '../../components/SideNav';
import Spinner from '../../components/Spinner';
import { checkAll, toggleChecked } from '../../redux/announces/announces-slice';
import {
  checkAnnouncesCount,
  exportAnnouncements,
  getAnnounces,
  getAnnouncesFilters,
  saveAnnounce,
} from '../../redux/announces/announcesThunk';
import { getCategories } from '../../redux/categories/categoriesThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  getPopularLabels,
  getRecommendedLabels,
} from '../../redux/labels/labelsThunk';
import { getFilePath, haveUserPermission } from '../../helpers/useHelper';
import { Announce } from '../../models/Announces';
import { Permissions } from '../../models/Permissions';

interface Props {}

const Announces: React.FC<Props> = () => {
  const { labelId } = useParams<{ labelId: string }>();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { announces, totalCount, loading, newAnnouncesCount } = useAppSelector(state => state.announces);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(24);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [showCheckedItems, setShowCheckedItems] = useState<boolean>(false);
  const [isCheckedAll, setCheckedAll] = useState(false);
  const [activeCategory, setActiveCategory] = useState<number>(0);
  const [filtersForm, setFiltersForm] = useState();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [titleConfirmModal, setTitleConfirmModal] = useState('');
  const [bodyConfirmModal, setBodyConfirmModal] = useState('');
  const [havePermission, setHavePermission] = useState(haveUserPermission(Permissions.AdList));
  const [prevState, setPrevState] = useState({
    currentPage: 0,
    itemsPerPage: 24,
  });
  const [hasNewAnnounces, setHasNewAnnounces] = useState(false);
  const [isOpenLabelsModal, setOpenLabelsModal] = useState(false);
  const [currentAnnouncement, setCurrentAnnouncement] = useState<Announce | undefined>(undefined);

  const activeCategoryRef = useRef(activeCategory);
  const filtersFormRef = useRef(filtersForm);

  useEffect(() => {
    const intervalId = setInterval(() => checkNewAnnounces(), 60000);

    dispatch(getCategories());
    dispatch(getRecommendedLabels());
    dispatch(getPopularLabels());
    window.addEventListener('resize', handleResize);

    return () => {
      clearInterval(intervalId);
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadAnnounces();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelId]);

  useEffect(() => {
    if (totalCount) {
      const pageCount = Math.ceil(totalCount / itemsPerPage);

      setPageCount(pageCount);
    }
  }, [totalCount, itemsPerPage]);

  useEffect(() => {
    activeCategory === 0 ? dispatch(getAnnouncesFilters()) : dispatch(getAnnouncesFilters(activeCategory));

    if(filtersForm) {
      setFiltersForm(undefined);
    }
    else{
      loadAnnounces();
    }

    activeCategoryRef.current = activeCategory;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeCategory]);

  useEffect(() => {
    let newState = {
      currentPage: currentPage,
      itemsPerPage: itemsPerPage,
    };

    if (
      JSON.stringify(prevState.currentPage) !== JSON.stringify(newState.currentPage) ||
      JSON.stringify(prevState.itemsPerPage) !== JSON.stringify(newState.itemsPerPage)
    ) {
      loadAnnounces();
      setPrevState(newState);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, currentPage]);

  useEffect(() => {
    if (currentPage !== 0) {
      setCurrentPage(0);
    } else {
      loadAnnounces();
    }

    filtersFormRef.current = filtersForm;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersForm]);

  useEffect(() => {
    setHasNewAnnounces(newAnnouncesCount > totalCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAnnouncesCount]);

  const loadAnnounces = () => {
    const filters = {
      offset: currentPage + 1,
      limit: itemsPerPage,
      category: activeCategory,
      filters: filtersForm,
      label: labelId || undefined,
    };

    setHasNewAnnounces(false);
    dispatch(getAnnounces(filters));
    closeModal();
    dispatch(checkAll(true));
    setCheckedAll(false);
  };

  const checkNewAnnounces = () => {
    dispatch(
      checkAnnouncesCount({
        offset: 0,
        limit: 0,
        category: activeCategoryRef.current,
        filters: filtersFormRef.current,
        label: labelId || undefined,
      }),
    );
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1081);
  };

  const ModalAction = () => {
    if (isModalOpen) {
      setIsModalOpen(false);
    } else {
      setIsModalOpen(true);
      setIsAction(true);
      setIsSearch(false);
      setIsFilter(false);
    }
  };

  const ModalFilter = () => {
    if (isModalOpen) {
      setIsModalOpen(false);
    } else {
      setIsModalOpen(true);
      setIsFilter(true);
      setIsAction(false);
      setIsSearch(false);
    }
  };

  const ModalSearch = () => {
    if (isModalOpen) {
      setIsModalOpen(false);
    } else {
      setIsModalOpen(true);
      setIsSearch(true);
      setIsFilter(false);
      setIsAction(false);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCheckboxChange = (id?: number, all?: boolean): void => {
    if (id) {
      const item = announces.find(item => item.id === id);

      if (item && item.checked && isCheckedAll) {
        setCheckedAll(false);
      }

      dispatch(toggleChecked(id));
    }

    if (all) {
      dispatch(checkAll(true));
    }
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
  };

  const openConfirmModal = (titleAction: string, bodyAction: string) => {
    if (announces.some((announce: any) => announce.checked)) {
      setTitleConfirmModal(`${titleAction} anunturi`);
      setBodyConfirmModal(`Esti sigur ca doresti sa ${bodyAction} anunturile selectate?`);
      setShowConfirmModal(true);
    } else {
      toast.error('Alegeti cel putin un anunt!');
    }
  };

  const exportConfirmedItems = () => {
    dispatch(exportAnnouncements(announces.filter(item => item.checked).map(item => item.id))).then(async res => {
      if (res.type === 'crm/exportAnnouncements/fulfilled') {
        const a = document.createElement('a');
        a.href = getFilePath(res.payload.file);
        a.setAttribute('download', res.payload.file.split('/').pop());
        document.body.appendChild(a);
        a.click();
        a.parentNode?.removeChild(a);

        dispatch(checkAll(false));
        setCheckedAll(false);
        closeModal();
      } else {
        toast.error('Eroare la exportarea anunturilor: ' + res.payload);
      }
    });
  };

  const confirmActionWithSelectedItems = () => {
    if (titleConfirmModal === 'Exportare anunturi') {
      exportConfirmedItems();
    }
  };

  const save = async (id: number) => {
    await dispatch(saveAnnounce(id)).then(res => {
      if (res?.payload?.id) {
        navigate('/properties/' + res?.payload?.id);
        toast.success('Anuntul a fost salvat cu succes!');
      } else if (res?.payload === 'ANNOUNCEMENT_IMPORTED') {
        toast.error('Acest anunt a fost salvat anterior');
      } else {
        toast.error('Eroare la salvarea anuntului!');
      }
    });
  };

  const onAddedLabels = () => {
    dispatch(getRecommendedLabels());
    dispatch(getPopularLabels());
    loadAnnounces();
  };

  return (
    <div className='flex-container announces-page display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        <Header title='Anunturi' subtitle='' isbutton={false} btnText='' />
        {havePermission ? (
          <>
            <div className={isMobile ? 'mobile-container main-wrapper-mobile' : 'desktop-container'}>
              <FilterCategory
                activeCategory={activeCategory}
                setActiveCategory={setActiveCategory}
                filtersForm={filtersForm}
                setFiltersForm={setFiltersForm}
              />
              <FilterListHeader
                pageCount={pageCount}
                currentPage={currentPage}
                itemsPerPage={itemsPerPage}
                totalCount={totalCount}
                count={announces?.length}
                showCheckedItems={showCheckedItems}
                checkedItemsLength={announces.filter(item => item.checked).length}
                setShowCheckedItems={setShowCheckedItems}
                setItemsPerPage={setItemsPerPage}
                setCurrentPage={setCurrentPage}
                handlePageClick={handlePageClick}
                showActions={true}
                exportItems={() => openConfirmModal('Exportare', 'exportezi')}
                options={[
                  {
                    value: 'export',
                    label: 'Exporta',
                  },
                ]}
              />
              {!isMobile && (
                <FilterList
                  setCheckedAll={(e: boolean) => {
                    setCheckedAll(e);

                    if (e) {
                      handleCheckboxChange(undefined, true);
                    } else {
                      dispatch(checkAll(false));
                    }
                  }}
                  isCheckedAll={isCheckedAll}
                />
              )}
              {loading ? (
                <div className='container'>
                  <Spinner />
                </div>
              ) : (
                <>
                  <div className='list-items-container'>
                    {announces
                      .filter(item => !showCheckedItems || item.checked)
                      .map((item: Announce, index) => {
                        return isMobile ? (
                          <AnouncesListCard
                            key={item.id}
                            item={item}
                            handleCheckboxChange={handleCheckboxChange}
                            save={() => save(item.id)}
                            onActionClick={() => setCurrentAnnouncement(item)}
                            showLabels={() => setOpenLabelsModal(true)}
                          />
                        ) : (
                          <AnnouncesListItem
                            key={'' + item.id + index}
                            item={item}
                            handleCheckboxChange={handleCheckboxChange}
                            save={() => save(item.id)}
                            onActionClick={() => setCurrentAnnouncement(item)}
                            showLabels={() => setOpenLabelsModal(true)}
                          />
                        );
                      })}
                    {!announces.some(item => !showCheckedItems || item.checked) && (
                      <p className='empty-text'>
                        {showCheckedItems ? '0 Anunturi selectati' : 'Cautarea ta a returnat 0 Rezultate'}
                      </p>
                    )}
                  </div>
                  {!showCheckedItems && pageCount > 1 && (
                    <Pagination
                      isSelectPage={false}
                      pageCount={pageCount}
                      currentPage={currentPage}
                      itemsPerPage={itemsPerPage}
                      setItemsPerPage={setItemsPerPage}
                      handlePageClick={handlePageClick}
                    />
                  )}
                </>
              )}
              {hasNewAnnounces && (
                <AnnouncesCountPopup count={newAnnouncesCount - totalCount} onButtonClick={loadAnnounces} />
              )}

              <FixedActions
                disabledActions={true}
                ModalAction={ModalAction}
                ModalFilter={ModalFilter}
                ModalSearch={ModalSearch}
              />
              <ModalMobile
                isModalOpen={isModalOpen}
                closeModal={closeModal}
                isAction={isAction}
                isSearch={isSearch}
                isFilter={isFilter}
                activeCategory={activeCategory}
                filtersForm={filtersForm}
                setActiveCategory={setActiveCategory}
                setFiltersForm={setFiltersForm}
              />
            </div>
          </>
        ) : (
          <MissingAccess />
        )}
      </div>

      <ModalAdLabels
        announce={currentAnnouncement}
        handleClose={() => setOpenLabelsModal(false)}
        showModal={isOpenLabelsModal}
        onSaved={onAddedLabels}
      />

      <ModalConfirm
        showModal={showConfirmModal}
        title={titleConfirmModal}
        body={bodyConfirmModal}
        onConfirmed={confirmActionWithSelectedItems}
        hideModal={() => setShowConfirmModal(false)}
      />
    </div>
  );
};

export default Announces;

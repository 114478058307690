import { createSlice } from '@reduxjs/toolkit';
import { Label } from '../../models/Labels';
import {
  addLabel,
  getLabels,
  getPopularLabels,
  getRecommendedLabels,
  searchLabels,
} from './labelsThunk';

type LabelsState = {
  labels: Label[];
  recommendedLabels: Label[];
  popularLabels: Label[];
  associatedLabels: Label[];
  foundLabels: Label[];
  searchQuery: string;
  label: null | Label;
  count: number;
  loadingLabels: boolean;
  loadingLabel: boolean;
  error: any;
};

const initialState: LabelsState = {
  labels: [],
  recommendedLabels: [],
  popularLabels: [],
  associatedLabels: [],
  foundLabels: [],
  searchQuery: '',
  count: 0,
  label: null,
  loadingLabels: false,
  loadingLabel: false,
  error: null,
};

const labelsSlice = createSlice({
  name: 'labels',
  initialState,
  reducers: {
    clearError(state) {
      state.error = null;
    },
    toggleChecked(state, action) {
      const item = state.labels.find(item => item.id === action.payload);

      if (item) {
        item.checked = !item.checked;
      }
    },
    checkAll(state, action) {
      state.labels.forEach(item => (item.checked = action.payload));
    },
    toggleAnnouncementsModalLabels(state, action) {
      const popularLabel = state.popularLabels.find(
        item => item.id === action.payload,
      );
      const recommendedLabel = state.recommendedLabels.find(
        item => item.id === action.payload,
      );

      if (popularLabel) {
        popularLabel.checked = !popularLabel.checked;
      }

      if (recommendedLabel) {
        recommendedLabel.checked = !recommendedLabel.checked;
      }

      const label = popularLabel || recommendedLabel || undefined;

      if (label) {
        if (label.checked) {
          state.associatedLabels = [...state.associatedLabels, label];
        } else {
          state.associatedLabels = state.associatedLabels.filter(
            l => l.id !== label.id,
          );
        }
      }
    },
    resetAnnouncementsModalLabels(state) {
      state.foundLabels = [];
      state.associatedLabels = [];
      state.popularLabels.forEach(item => (item.checked = false));
      state.recommendedLabels.forEach(item => (item.checked = false));
    },
    setAssociatedLabels(state, action) {
      state.associatedLabels = action.payload ?? [];
      state.popularLabels.forEach(
        item =>
          (item.checked = action.payload.some(
            (label: Label) => label.id === item.id,
          )),
      );
      state.recommendedLabels.forEach(
        item =>
          (item.checked = action.payload.some(
            (label: Label) => label.id === item.id,
          )),
      );
    },
    setSearchQuery(state, action) {
      state.searchQuery = action.payload ?? '';
    },
    clearFoundLabels(state) {
      state.foundLabels = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getLabels.pending, state => {
        state.loadingLabels = true;
        state.error = null;
      })
      .addCase(getLabels.fulfilled, (state, { payload }) => {
        state.labels = payload.labels;
        state.count = payload.totalCount;
        state.loadingLabels = false;
        state.error = null;
      })
      .addCase(getLabels.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingLabels = false;
      })
      .addCase(getRecommendedLabels.fulfilled, (state, { payload }) => {
        state.recommendedLabels = payload;
      })
      .addCase(getPopularLabels.fulfilled, (state, { payload }) => {
        state.popularLabels = payload;
      })
      .addCase(searchLabels.fulfilled, (state, { payload }) => {
        if (
          !payload.some((label: Label) => label.title === state.searchQuery)
        ) {
          payload.unshift({
            id: -1,
            title: state.searchQuery + ' (Eticheta noua)',
          });
        }

        state.foundLabels = payload;
      })
      .addCase(addLabel.pending, state => {
        state.loadingLabel = true;
        state.error = null;
      })
      .addCase(addLabel.fulfilled, (state, { payload }) => {
        state.label = payload;
        state.loadingLabel = false;
        state.error = null;
      })
      .addCase(addLabel.rejected, (state, action) => {
        state.error = action.payload;
        state.loadingLabel = false;
      });
  },
});

export const {
  clearError,
  toggleChecked,
  checkAll,
  toggleAnnouncementsModalLabels,
  resetAnnouncementsModalLabels,
  setAssociatedLabels,
  setSearchQuery,
  clearFoundLabels,
} = labelsSlice.actions;

export default labelsSlice.reducer;

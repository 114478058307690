import { unwrapResult } from '@reduxjs/toolkit';
import React, { FormEvent, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import {
  authentication,
  fetchToken,
  getUserProfile,
} from '../../redux/auth/authThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import useHelper, { showError } from '../../helpers/useHelper';
import { AuthenticationProps } from '../../models/Auth';
import background from '../../assets/svg/Loginbg.svg';
import Envelope from '../../assets/svg/envelope.svg';
import Lock from '../../assets/svg/lock.svg';
import './Login.scss';

interface Props {}

const Login: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const { diplayErrorMessages } = useHelper();
  const { error, loading } = useAppSelector(state => state.auth);
  const [formData, setFormData] = useState<AuthenticationProps>({
    username: '',
    password: '',
    keepLogin: false,
  });

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (localStorage.getItem('bearerToken')) {
        localStorage.removeItem('bearerToken');
      }

      const result = await dispatch(authentication(formData));
      unwrapResult(result);

      const tokenResult = await dispatch(fetchToken(result.payload));
      unwrapResult(tokenResult);

      const profileResult = await dispatch(getUserProfile());

      localStorage.setItem('sideNavState', 'true');
      unwrapResult(profileResult);
    } catch (error) {
      diplayErrorMessages(error);
    }
  };

  return (
    <div className='login-container'>
      <img className='loginbgimg' src={background} alt='background' />
      <div className='form-conatiner'>
        <div className='form-title-block'>
          <span className='logo' aria-label='logo ADAI'>
            <svg
              width='51'
              height='51'
              viewBox='0 0 51 51'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                id='Vector'
                fillRule='evenodd'
                clipRule='evenodd'
                d='M23.6112 3.65736L21.7769 7.3148L26.9872 17.7801C29.8528 23.5361 32.225 28.283 32.2587 28.3287C32.2923 28.3745 33.9638 31.7065 35.9731 35.7333C37.9824 39.76 39.828 43.4384 40.0743 43.9074L40.5223 44.7602L36.2066 44.7647C33.833 44.7671 31.6496 44.7792 31.3547 44.7915C31.0598 44.8038 27.96 44.8205 24.4664 44.8288L18.1143 44.8437L17.7032 45.4883C17.4771 45.8428 17.1032 46.4325 16.8725 46.7985C16.6417 47.1646 15.9467 48.2597 15.3279 49.2321L14.203 51H32.6015H51L47.5811 44.157C45.7007 40.3934 42.2966 33.5701 40.0163 28.9943C37.7362 24.4184 34.9154 18.7652 33.748 16.4315C32.5806 14.0978 30.2597 9.44606 28.5903 6.09421C26.9209 2.74235 25.5304 0 25.5003 0C25.4702 0 24.6201 1.64581 23.6112 3.65736ZM14.9754 20.9865C13.401 24.1553 11.157 28.6573 9.98872 30.991C8.82042 33.3247 6.09504 38.7815 3.93228 43.1171L0 51L3.73619 50.9996L7.47246 50.9991L10.4835 44.947L13.4946 38.8948L21.2697 38.8736C25.546 38.8619 29.0421 38.8245 29.0387 38.7904C29.0354 38.7563 28.2278 37.418 27.2443 35.8165L25.456 32.9046L20.9791 32.883L16.5022 32.8616L19.02 27.7968C20.4048 25.0113 21.5378 22.7143 21.5378 22.6925C21.5378 22.627 17.8998 15.2251 17.8676 15.2251C17.8513 15.2251 16.5499 17.8178 14.9754 20.9865Z'
                fill='#6F6BF9'
              />
            </svg>
          </span>
          <h4 className='title'>Log In</h4>
        </div>
        <form onSubmit={handleSubmit}>
          <div className='inputs-field'>
            <img src={Envelope} alt='' className='icon' />
            <input
              placeholder='Email Address'
              type='username'
              id='username'
              className={error?.username ? 'has-error' : ''}
              value={formData.username}
              onChange={e =>
                setFormData({
                  ...formData,
                  username: e.target.value,
                })
              }
            />
            {error?.username && (
              <p className='error-message'>{showError(error.username)}</p>
            )}
          </div>
          <div className='inputs-field'>
            <img src={Lock} alt='' className='icon' />
            <input
              autoComplete=''
              placeholder='Password'
              type='password'
              id='password'
              className={error?.password ? 'has-error' : ''}
              value={formData.password}
              onChange={e =>
                setFormData({
                  ...formData,
                  password: e.target.value,
                })
              }
            />
            {error?.password && (
              <p className='error-message'>{showError(error.password)}</p>
            )}
          </div>
          <div className='footer-login'>
            <label className='custom-checkbox'>
              Keep me logged in
              <input
                type='checkbox'
                checked={formData.keepLogin}
                onChange={e =>
                  setFormData({
                    ...formData,
                    keepLogin: e.target.checked,
                  })
                }
              />
              <span className='checkmark'></span>
            </label>
            <Link className='forgot-password' to='/forgot-password'>
              Forgot Password
            </Link>
          </div>
          <button type='submit' disabled={loading} className='submit-button'>
            Log In
            {loading && <Spinner animation='border' />}
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;

import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { resolveActivity } from '../../redux/activities/activitiesThunk';
import { useAppDispatch } from '../../redux/hooks';
import { getFilePath } from '../../helpers/useHelper';
import { ActivityItem } from '../../models/Activities';
import AssociateIcon from '../../assets/svg/asociate.svg';
import IconCheck from '../../icons/IconCheck';
import IconPencil from '../../icons/IconPencil';
import './ActivityListItem.scss';

interface Props {
  item: ActivityItem;
  checked: boolean;
  handleCheckboxChange: (id: number) => void;
  refreshActivities: () => void;
}

const ActivityListItem: React.FC<Props> = ({
  item,
  checked,
  handleCheckboxChange,
  refreshActivities,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1081);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 1081);
  };
  const getStatus = (status: number) => {
    switch (status) {
      case 5:
        return 'Rezolvata';
      case 6:
        return 'Viitoare';
      case 7:
        return 'Depasita';
      default:
        return '';
    }
  };

  const resolve = (id: number) => {
    dispatch(resolveActivity(id))
      .then(res => {
        if (res.payload.id) refreshActivities();
      })
      .catch((res: any) => {
        if (res.payload.status === 409) {
          refreshActivities();
        }
      });
  };

  return (
    <div
      className={`activity-item announces-list-item ${checked ? 'active' : ''} ${item.status === 5 ? 'opacity' : ''}`}
    >
      <div className='mobile-lines'>
        <div className='line-border'></div>
        <div className='checkbox-container'>
          <Form.Group id='formGridCheckbox'>
            <Form.Check
              type='checkbox'
              checked={checked}
              readOnly
              onClick={() => handleCheckboxChange(item.id)}
            />
          </Form.Group>
        </div>
        <div className='line-border'></div>
      </div>
      <div className='list-item-content'>
        <div className='column-item'>
          <div className='mobile-text'>Status</div>
          <div className='label-item-tag label-item-mobile'>
            {getStatus(item.status)}
          </div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Tip</div>
          <div className='info-type label-item-mobile'>
            {item?.activityTypeIconPath ? (
              <img src={getFilePath(item?.activityTypeIconPath)} alt='' />
            ) : null}
            {item.typeTitle}
          </div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Titlu</div>
          <div className='info-title label-item-mobile '>{item?.title}</div>
        </div>
        {(item.contacts.length > 0 || !isMobile) && (
          <div className='column-item'>
            <div className='mobile-text'>Contacte Asociate</div>
            <div className='info-contacts'>
              {item.contacts.map((contact, key) => (
                <div
                  className='label-item-rounded-info-sm label-item-mobile'
                  key={key + contact.id}
                >
                  {contact.name + ' ' + contact.surname}
                </div>
              ))}
            </div>
          </div>
        )}

        {(item.properties.length > 0 || !isMobile) && (
          <div className='column-item'>
            <div className='mobile-text'>Prop. Asociate</div>
            <div className='info-contacts'>
              {item.properties.map((property, key) => (
                <div
                  className='label-item-rounded-info-sm label-item-mobile'
                  key={key + property.id}
                >
                  <img src={AssociateIcon} alt='AssociateIcon' /> {property.id}
                </div>
              ))}
            </div>
          </div>
        )}

        {!isMobile && (
          <div className='column-item'>
            {/* <div className='mobile-text'>Carere Asociate</div> */}
            {/* <div className=' label-item-rounded-info-sm label-item-mobile'>
            <img src={AssociateIcon} alt='AssociateIcon' /> 38
          </div> */}
          </div>
        )}
        <div className='column-item'>
          <div className='mobile-text'>Data</div>
          <div className='info-date'>
            <span className='label-item-mobile'>
              {item?.allDay === 1
                ? moment(item.dateStart).format('DD.MM.YYYY')
                : moment(item.dateStart).format('DD.MM.YYYY HH:mm')}
            </span>
            <span className='label-item-mobile'>
              {item?.allDay === 1
                ? moment(item.dateEnd).format('DD.MM.YYYY')
                : moment(item.dateEnd).format('DD.MM.YYYY HH:mm')}
            </span>
          </div>
        </div>
        <div className='line-border'></div>
        <div className='info-icons'>
          {item.status !== 5 && (
            <>
              <button
                className='button-square-md'
                aria-label=''
                data-tooltip-id='edit-status'
                data-tooltip-content='Rezolvat'
                onClick={() => resolve(item.id)}
              >
                <IconCheck />
              </button>
              <Tooltip id='edit-status' />
            </>
          )}
          <button
            className='button-square-md'
            aria-label=''
            data-tooltip-id='edit'
            data-tooltip-content='Modifica activitate'
            onClick={() => navigate('/activities/' + item?.id)}
          >
            <IconPencil />
          </button>
          <Tooltip id='edit' />
        </div>
      </div>
    </div>
  );
};

export default ActivityListItem;

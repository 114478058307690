import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useAppDispatch } from '../../redux/hooks';
import { removeLabel } from '../../redux/labels/labelsThunk';
import { Label } from '../../models/Labels';
import IconTrash from '../../icons/IconTrash';
import ModalConfirm from '../ModalConfirm';
import './AnnouncesListItemTag.scss';

interface Props {
  label: Label;
  onRemovedLabel: any;
}

const AnnouncesListItemTag: React.FC<Props> = ({ label, onRemovedLabel }) => {
  const dispatch = useAppDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const onRemoveLabel = () => {
    setShowDeleteModal(false);
    dispatch(removeLabel(label.id)).then((response: any) => {
      if (response.error) {
        toast.error(
          `Eroare la stergere etichetei ${typeof response.payload === 'string' ? ': ' + response.payload : ''}`,
        );
      } else {
        toast.success(`Eticheta a fost stearsa.`);
        onRemovedLabel();
      }
    });
  };

  return (
    <div className='announces-list-item item-tag'>
      <div className='list-item-content'>
        <div className='column-item'>
          <div className='mobile-text'>Id</div>
          <div className='info-nr label-item-tag label-item-mobile'>
            {label.id}
          </div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Titlu</div>
          <div className='info-bold label-item-mobile'>{label.title}</div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Anunturi</div>
          <div className='info-text label-item-mobile'>
            {!!label.announcementsCount && (
              <a
                href={`/tag-ads/${label.id}`}
              >{`ImoAP: ${label.announcementsCount} anunt${label.announcementsCount !== 1 ? 'uri' : ''}`}</a>
            )}
          </div>
        </div>
        <div className='column-item'>
          <div className='mobile-text'>Data</div>
          <div className='info-date label-item-mobile'>{label.dateCreated}</div>
        </div>
        <div className='line-border'></div>
        <div className='info-icons'>
          <button
            className='button-square-md'
            aria-label=''
            onClick={() => setShowDeleteModal(true)}
          >
            <IconTrash />
          </button>
        </div>
      </div>
      <ModalConfirm
        showModal={showDeleteModal}
        title='Stergere eticheta'
        body='Esti sigur ca doresti sa stergi eticheta?'
        onConfirmed={onRemoveLabel}
        hideModal={() => setShowDeleteModal(false)}
      />
    </div>
  );
};

export default AnnouncesListItemTag;

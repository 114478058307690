import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  clearCities,
  clearZona,
} from '../../redux/localities/localities-slice';
import { getCities, getZones } from '../../redux/localities/localitiesThunk';
import { uploadImage } from '../../redux/media/mediaThunk';
import { getFilePath, parseValues } from '../../helpers/useHelper';
import { AgentRequestDto, UserProfile } from '../../models/AgentsDto';
import DeleteIcon from '../../assets/svg/deletebtn.svg';
import editIcon from '../../assets/svg/editbtn.svg';
import GlobeIcon from '../../assets/svg/globeitem.svg';
import IconAdd from '../../icons/IconAdd';
import IconTrash from '../../icons/IconTrash';
import IconUploadPhoto from '../../icons/IconUploadPhoto';
import Input from '../Input/Input';
import ModalConfirm from '../ModalConfirm';
import MultiselectCheckboxes from '../MultiselectCheckboxes';
import Select from '../Select';
import './AddAgentForm.scss';

interface Props {
  user: UserProfile | null;
  saveAgent: () => void;
  resetForm: () => void;
  profileImage: any;
  setProfileImage: (image: any) => void;
  formData: AgentRequestDto;
  setFormData: (form: any) => void;
  formDataErrors: any;
}

const AddAgentForm: React.FC<Props> = ({
  user,
  saveAgent,
  resetForm,
  profileImage,
  setProfileImage,
  formData,
  setFormData,
  formDataErrors,
}) => {
  const dispatch = useAppDispatch();

  const { languages } = useAppSelector(state => state.languages);
  const { specializations } = useAppSelector(state => state.specializations);
  const { county, cities, zona } = useAppSelector(state => state.localities);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (user) {
      getFormCities();
      dispatch(
        getZones({
          parent: user?.profile?.town ? user.profile.town.id : cities?.[0]?.id,
        }),
      );
    } else {
      dispatch(clearCities());
      dispatch(clearZona());
    }
  }, [user]);

  useEffect(() => {
    if (county && formData.body?.countyId && cities.length === 0) {
      getFormCities();
    }
    // eslint-disable-next-line
  }, [county]);

  useEffect(() => {
    if (cities && formData.body?.townId && zona.length === 0) {
      dispatch(
        getZones({
          parent: user?.profile?.town ? user.profile.town.id : cities?.[0]?.id,
        }),
      );
    }
    // eslint-disable-next-line
  }, [cities]);

  const getFormCities = () => {
    dispatch(
      getCities({
        parent: user?.profile?.county
          ? user.profile.county.id
          : county?.[0]?.id,
      }),
    );
  };

  const onStatusChange = (event: any) => {
    setFormData((form: any) => {
      form.body.status = event.target.checked ? 1 : 0;

      return { ...form };
    });
  };

  const onInputChange = (event: any) => {
    const { value, name } = event.target;
    setFormData((formData: any) => {
      if (name.includes('phoneNumbers')) {
        const matches = name.match(/\[(\d+)\]/);
        const index = parseInt(matches[1]);
        const editPhoneNumbers = [...formData.body.phoneNumbers];
        editPhoneNumbers[index] = value;
        formData.body.phoneNumbers = [...editPhoneNumbers];
      } else {
        formData.body[name] = value;
      }

      return { ...formData };
    });
  };

  const getSelectValue = (
    isMulti: boolean,
    fieldToCheck: any,
    optionValue?: any,
  ) => {
    if (isMulti) {
      return formData.body
        ? (
            Object.entries(formData.body).find(
              pair => pair[0] === fieldToCheck,
            )?.[1] as number[]
          ).map((s: number) => String(s)) ?? null
        : null;
    } else {
      const option = fieldToCheck
        ? optionValue.find((o: any) => o.id === fieldToCheck)
        : null;

      return option
        ? {
            value: option.id,
            label: option.title,
          }
        : null;
    }
  };

  const getSelectOptions = (objectsToMap: any) => {
    return objectsToMap.map((obj: any) => {
      return { value: obj.id, label: obj.title };
    });
  };

  const onSelectChange = (event: any, name: string) => {
    setFormData((formData: any) => {
      if (name === 'countyId' && formData.body['countyId'] !== event.value) {
        dispatch(getCities({ parent: event.value }));
        dispatch(clearZona());
        formData.body['townId'] = 0;
        formData.body['zones'] = [];
      } else if (name === 'townId' && formData.body['townId'] !== event.value) {
        dispatch(getZones({ parent: event.value }));
        formData.body['zones'] = [];
      }

      formData.body[name] = Array.isArray(event) ? event : event.value;

      return { ...formData };
    });
  };

  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      dispatch(
        uploadImage({
          file: file,
          setProgress: (e: number) => {},
        }),
      ).then(res => {
        if (res.payload?.id) {
          setProfileImage(getFilePath(res.payload.filePath));
          setFormData((formData: any) => {
            formData.body.mediaId = res.payload.id;

            return { ...formData };
          });
        }
      });
    }
  };

  const deleteProfileImage = () => {
    setProfileImage(null);
    setFormData((formData: any) => {
      formData.body.mediaId = 0;

      return { ...formData };
    });
  };

  const addPhoneNumber = () => {
    setFormData((formData: any) => {
      formData.body.phoneNumbers = [...formData.body.phoneNumbers, ''];

      return { ...formData };
    });
  };

  const removePhoneNumber = (index: number) => {
    setFormData((formData: any) => {
      let phoneNumbers = [...formData.body.phoneNumbers];
      const phoneToRemove = phoneNumbers.splice(index, 1);

      phoneNumbers = phoneNumbers.filter(phone => phone !== phoneToRemove);
      formData.body.phoneNumbers = phoneNumbers;

      return { ...formData };
    });
  };

  const generatePassword = () => {
    const lowercaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const uppercaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numberChars = '0123456789';
    const symbolChars = '!@#*_+:;?,.-=';

    let password = '';

    // Function to get a random character from a given character set
    const randomChar = (chars: string) =>
      chars[Math.floor(Math.random() * chars.length)];

    const charCategories = [
      lowercaseChars,
      uppercaseChars,
      numberChars,
      symbolChars,
    ];

    // Add one character from each category
    charCategories.forEach(chars => {
      password += randomChar(chars);
    });

    // Add additional characters until reaching the desired password length
    for (let i = 4; i < 12; i++) {
      const randomCategoryIndex = Math.floor(
        Math.random() * charCategories.length,
      );
      const randomChars = charCategories[randomCategoryIndex];
      password += randomChar(randomChars);
    }

    // Shuffle the password characters
    password = password
      .split('')
      .sort(() => Math.random() - 0.5)
      .join('');

    setFormData((formData: any) => {
      formData.body.password = password;
      formData.body.confirmPassword = password;

      return { ...formData };
    });
  };

  const isAllowedToAddPhone = () => {
    const phones = formData.body?.phoneNumbers;

    return !(
      phones &&
      phones.length > 0 &&
      (phones.length > 2 || phones.some((phone: string) => phone.length === 0))
    );
    //  max 3 phones      //  not allowed empty phones
  };

  return (
    <div className='add-agent-container'>
      <div className='form-container'>
        <div className='img-upload-block'>
          <div className='image-container'>
            <div className='upload-container'>
              <label htmlFor='file-upload' className='custom-file-upload'>
                {profileImage ? (
                  <img src={profileImage} alt='' />
                ) : (
                  <IconUploadPhoto />
                )}
              </label>
              <input
                id='file-upload'
                type='file'
                accept='image/*'
                onChange={handleImageChange}
              />
              <button
                className='edit-btn'
                aria-label='Editeaza'
                onClick={() => document.getElementById('file-upload')?.click()}
              >
                <img src={editIcon} alt='editIcon' />
              </button>
              <button
                className='delete-btn'
                aria-label='Sterge'
                onClick={() => profileImage && setShowDeleteModal(true)}
              >
                <img src={DeleteIcon} alt='DeleteIcon' />
              </button>
            </div>
          </div>
          <div className='radion-block'>
            <Form.Check
              reverse
              type='switch'
              id='custom-switch'
              label='Status'
              checked={formData.body?.status === 1}
              onChange={onStatusChange}
            />
          </div>
        </div>
        <div className='form-block'>
          <h4 className='title'>Informatii</h4>
          <form>
            <div className='form-line-section'>
              <div className='form-column-section'>
                <Input
                  name='fullName'
                  inlineLabel='Nume'
                  labelText={true}
                  value={formData.body?.fullName}
                  onChange={onInputChange}
                  error={formDataErrors?.fullName}
                />
                <div className='input-group'>
                  {formData.body?.phoneNumbers?.map(
                    (phoneNumber, index, array) => (
                      <div key={index} className='input-group phone-number'>
                        <Input
                          inlineLabel='Telefon'
                          labelText={true}
                          name={`phoneNumbers[${index}]`}
                          value={phoneNumber}
                          onChange={onInputChange}
                        />
                        {array.length > 1 && (
                          <span
                            className='delete-btn'
                            aria-label='Sterge'
                            onClick={() => {
                              removePhoneNumber(index);
                            }}
                          >
                            <IconTrash />
                          </span>
                        )}
                      </div>
                    ),
                  )}
                  <span
                    className={`add-block ${!isAllowedToAddPhone() && 'd-none'}`}
                    onClick={addPhoneNumber}
                  >
                    <IconAdd />
                    Adauga Telefon
                  </span>
                </div>
                <Input
                  inlineLabel='Email'
                  labelText={true}
                  name='email'
                  value={formData.body?.email}
                  onChange={onInputChange}
                  error={formDataErrors?.email}
                />
              </div>
              <div className='form-column-section'>
                <MultiselectCheckboxes
                  labelText={true}
                  inlineLabel='Specializat'
                  onChange={(e: any) => onSelectChange(e, 'specializations')}
                  value={getSelectValue(true, 'specializations')}
                  options={parseValues(specializations) ?? []}
                  placeholder='Select'
                />
                <MultiselectCheckboxes
                  labelText={true}
                  inlineLabel='Limbi straine'
                  onChange={(e: any) => onSelectChange(e, 'languages')}
                  value={getSelectValue(true, 'languages')}
                  options={parseValues(languages) ?? []}
                  placeholder='Select'
                />
                <Input
                  inlineLabel='Nr legitimatie'
                  labelText={true}
                  name='identificationNumber'
                  value={formData.body?.identificationNumber || ''}
                  onChange={onInputChange}
                />
              </div>
              <div className='form-column-section'>
                <Select
                  labelText={true}
                  inlineLabel='Judet'
                  onChange={(event: any) => onSelectChange(event, 'countyId')}
                  value={getSelectValue(false, formData.body?.countyId, county)}
                  options={getSelectOptions(county)}
                  placeholder='Select'
                />
                <Select
                  labelText={true}
                  inlineLabel='Oras'
                  onChange={(event: any) => onSelectChange(event, 'townId')}
                  value={getSelectValue(false, formData.body?.townId, cities)}
                  options={getSelectOptions(cities)}
                  placeholder='Select'
                />
                <MultiselectCheckboxes
                  labelText={true}
                  inlineLabel='Zona'
                  onChange={(e: any) => onSelectChange(e, 'zones')}
                  value={getSelectValue(true, 'zones')}
                  options={parseValues(zona) ?? []}
                  placeholder='Select'
                />
              </div>
            </div>
            <div className='form-line-section'>
              <Input
                inlineLabel='Functia'
                labelText={true}
                value={formData.body?.position || ''}
                name='position'
                onChange={onInputChange}
              />
            </div>
            <div className='form-line-section'>
              <div className='form-item-group'>
                <label className='form-item-group-label' htmlFor=''>
                  Descriere
                </label>
                <div className='form-item-group-block'>
                  <textarea
                    id=''
                    className='form-item-control'
                    value={formData.body?.description || ''}
                    name='description'
                    onChange={onInputChange}
                  ></textarea>
                </div>
              </div>
            </div>
            <div className='form-line-section'>
              <div className='reset-password'>
                <p id='change-password'>Schimba Parola</p>
                <div className='display-flex'>
                  <div className='input-with-icon'>
                    <Input
                      id='new-password'
                      inlineLabel='Parola noua'
                      labelText={true}
                      autoComplete={'new-password'}
                      name='password'
                      value={formData.body?.password || ''}
                      onChange={onInputChange}
                      isPassword={true}
                      error={formDataErrors?.password}
                    />
                    <img
                      src={GlobeIcon}
                      alt='GlobeIcon'
                      className='input-icon'
                      title='Genereaza parola'
                      style={{
                        top: `${formDataErrors?.password ? '53%' : '68%'}`,
                      }}
                      onClick={generatePassword}
                    />
                  </div>
                  <Input
                    id='confirm-password'
                    inlineLabel='Confirma Parola'
                    labelText={true}
                    name='confirmPassword'
                    value={formData.body?.confirmPassword || ''}
                    onChange={onInputChange}
                    isPassword={true}
                    error={formDataErrors?.confirmPassword}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className='btns-container'>
        <button className='button-blue button-md' onClick={saveAgent}>
          Salveaza
        </button>
        <button className='reset-btn' onClick={resetForm}>
          Reseteaza
        </button>
      </div>
      <ModalConfirm
        showModal={showDeleteModal}
        title='Stergerea pozei de profil'
        body='Esti sigur ca doresti sa stergi poza de profil?'
        onConfirmed={deleteProfileImage}
        hideModal={() => setShowDeleteModal(false)}
      />
    </div>
  );
};

export default AddAgentForm;

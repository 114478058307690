import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ActivityListItem from '../../components/ActivityListItem/ActivityListItem';
import ActivityListModal from '../../components/ActivityListModal/ActivityListModal';
import FilterActivityList from '../../components/FilterActivityList/FilterActivityList';
import FilterListHeader from '../../components/FilterListHeader';
import FixedActions from '../../components/FixedActions/FixedActions';
import Header from '../../components/Header';
import MissingAccess from '../../components/MissingAccess';
import ModalConfirm from '../../components/ModalConfirm';
import Pagination from '../../components/Pagination';
import SideNav from '../../components/SideNav';
import Spinner from '../../components/Spinner';
import { getActivities, getActivitiesTypes, removeActivities } from '../../redux/activities/activitiesThunk';
import { getContacts } from '../../redux/contacts/contactsThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { getProperties } from '../../redux/properties/propertiesThunk';
import { haveUserPermission } from '../../helpers/useHelper';
import { ActivityItem } from '../../models/Activities';
import { Permissions } from '../../models/Permissions';
import IconPlus from '../../icons/IconPlus';
import './ActivityList.scss';
import { checkAll, toggleChecked } from '../../redux/activities/activities-slice';
import { getRequests } from '../../redux/requests/requestsThunk';

interface Props {}

const ActivityList: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { activities, activitiesLoading, totalCount, activitiesTypes } = useAppSelector(state => state.activities);
  const { contacts } = useAppSelector(state => state.contacts);
  const { properties } = useAppSelector(state => state.properties);
  const { requests } = useAppSelector(state => state.requests).requestsListState;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAction, setIsAction] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(24);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [sort, setSort] = useState('');
  const [showCheckedItems, setShowCheckedItems] = useState<boolean>(false);
  const [isCheckedAll, setCheckedAll] = useState(false);
  const [filtersForm, setFiltersForm] = useState({});
  const [loadingFilters, setLoadingFilters] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [titleConfirmModal, setTitleConfirmModal] = useState('');
  const [bodyConfirmModal, setBodyConfirmModal] = useState('');
  const [havePermission, setHavePermission] = useState(haveUserPermission(Permissions.ActivityMyList));

  useEffect(() => {
    contacts.length === 0 && dispatch(getContacts());
    properties.length === 0 && dispatch(getProperties({}));
    requests.length === 0 && dispatch(getRequests({ limit: -1 }));
    activitiesTypes.length === 0 && dispatch(getActivitiesTypes());
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (totalCount) {
      const pageCount = Math.ceil(totalCount / itemsPerPage);

      setPageCount(pageCount);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCount, itemsPerPage]);

  useEffect(() => {
    refreshActivities();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, currentPage, sort]);

  useEffect(() => {
    if (Object.keys(filtersForm).length > 0) {
      if (currentPage === 0) {
        refreshActivities();
      } else {
        setCurrentPage(0);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersForm]);

  const ModalAction = () => {
    setIsModalOpen(true);
    setIsAction(true);
    setIsSearch(false);
    setIsFilter(false);
  };

  const ModalFilter = () => {
    setIsModalOpen(true);
    setIsFilter(true);
    setIsAction(false);
    setIsSearch(false);
  };

  const ModalSearch = () => {
    setIsModalOpen(true);
    setIsSearch(true);
    setIsFilter(false);
    setIsAction(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCheckboxChange = (id?: number, all?: boolean): void => {
    if (id) {
      const activity = activities.find(activity => activity.id === id);

      if (activity && activity.checked && isCheckedAll) {
        setCheckedAll(false);
      }

      dispatch(toggleChecked(id));
    }

    if (all) {
      dispatch(checkAll(true));
    }
  };

  const handlePageClick = (selectedItem: { selected: number }) => {
    setCurrentPage(selectedItem.selected);
  };

  const refreshActivities = () => {
    let filters = {
      offset: currentPage + 1,
      limit: itemsPerPage,
      sort: sort,
    };
    filters = filtersForm ? { ...filters, ...filtersForm } : filters;

    setLoadingFilters(true);
    dispatch(getActivities(filters)).then(() => setLoadingFilters(false));
  };

  const editItems = () => {
    if (activities.some(activity => activity.checked)) {
      navigate(`/activities/${activities.filter(item => item.checked)[0].id}`);
    } else {
      toast.error('Alegeti o activitate!');
    }
  };

  const openConfirmModal = (titleAction: string, bodyAction: string) => {
    if (activities.some(item => item.checked)) {
      setTitleConfirmModal(`${titleAction} activitati`);
      setBodyConfirmModal(`Esti sigur ca doresti sa ${bodyAction} activitatile selectate?`);
      setShowConfirmModal(true);
    } else {
      toast.error('Alegeti cel putin o activitate!');
    }
  };

  const removeConfirmedItems = () => {
    dispatch(
      removeActivities(
        activities
          .filter(item => item.checked)
          .map(checked => checked.id)
          .join(','),
      ),
    ).then((res: any) => {
      let filters = {
        offset: currentPage + 1,
        limit: itemsPerPage,
        sort: sort,
      };

      if (res.type === 'auth/removeActivities/fulfilled') {
        toast.success('Activitatile selectate au fost sterse!');
        setLoadingFilters(true);
        dispatch(getActivities(filters)).then(() => setLoadingFilters(false));
        dispatch(checkAll(false));
        setCheckedAll(false);
        setIsModalOpen(false);
      }
    });
  };

  const confirmActionWithSelectedItems = () => {
    if (titleConfirmModal === 'Stergere activitati') {
      removeConfirmedItems();
    }
  };

  return (
    <div className='flex-container display-flex'>
      <SideNav />
      <div className='display-flex flex-column main-wrapper'>
        {activitiesLoading && activities.length === 0 && Object.keys(filtersForm).length === 0 ? (
          <Spinner />
        ) : (
          <>
            <Header
              title='Listare Activitati'
              subtitle='Toate activitatile tale sau ale colegilor tai'
              isbutton={havePermission}
              btnEvent={() => navigate('/add-activity')}
              btnText='Adauga Activitate'
            />

            {havePermission ? (
              <>
                <FilterListHeader
                  pageCount={pageCount}
                  currentPage={currentPage}
                  itemsPerPage={itemsPerPage}
                  totalCount={totalCount}
                  count={activities?.length}
                  showCheckedItems={showCheckedItems}
                  checkedItemsLength={activities.filter(item => item.checked).length}
                  setShowCheckedItems={setShowCheckedItems}
                  setItemsPerPage={setItemsPerPage}
                  setCurrentPage={setCurrentPage}
                  handlePageClick={handlePageClick}
                  removeItems={() => openConfirmModal('Stergere', 'stergi')}
                  editItems={editItems}
                  loading={loadingFilters}
                />
                <FilterActivityList
                  setCheckedAll={(e: boolean) => {
                    setCheckedAll(e);

                    if (e) {
                      handleCheckboxChange(undefined, true);
                    } else {
                      dispatch(checkAll(false));
                      closeModal();
                    }
                  }}
                  isCheckedAll={isCheckedAll}
                  sortActivity={setSort}
                  sort={sort}
                  filtersForm={filtersForm}
                  setFiltersForm={setFiltersForm}
                />
                {loadingFilters ? (
                  <Spinner />
                ) : (
                  <>
                    <div className='list-items-container'>
                      {activities
                        .filter(item => !showCheckedItems || item.checked)
                        .map((activity: ActivityItem) => (
                          <ActivityListItem
                            key={activity.id + 'checked'}
                            item={activity}
                            checked={activity.checked}
                            handleCheckboxChange={handleCheckboxChange}
                            refreshActivities={refreshActivities}
                          />
                        ))}
                      {!activities.some(item => !showCheckedItems || item.checked) && (
                        <p className='empty-text'>
                          {showCheckedItems ? '0 Activitati selectati' : 'Cautarea ta a returnat 0 Rezultate'}
                        </p>
                      )}
                    </div>
                    {!showCheckedItems && pageCount > 1 && (
                      <Pagination
                        isSelectPage={false}
                        pageCount={pageCount}
                        currentPage={currentPage}
                        itemsPerPage={itemsPerPage}
                        setItemsPerPage={setItemsPerPage}
                        handlePageClick={handlePageClick}
                      />
                    )}
                  </>
                )}

                <FixedActions
                  disabledActions={activities.every(item => !item.checked)}
                  ModalAction={ModalAction}
                  ModalFilter={ModalFilter}
                  ModalSearch={ModalSearch}
                />
                <div className='add-btn' onClick={() => navigate('/add-activity')}>
                  <IconPlus />
                </div>
                <ActivityListModal
                  isModalOpen={isModalOpen}
                  closeModal={closeModal}
                  isAction={isAction}
                  isSearch={isSearch}
                  isFilter={isFilter}
                  filtersForm={filtersForm}
                  setFiltersForm={setFiltersForm}
                  removeItems={() => openConfirmModal('Stergere', 'stergi')}
                  editItems={editItems}
                  setCheckedAll={(e: boolean) => {
                    setCheckedAll(e);

                    if (e) {
                      handleCheckboxChange(undefined, true);
                    } else {
                      dispatch(checkAll(false));
                      closeModal();
                    }
                  }}
                  isCheckedAll={isCheckedAll}
                  sortActivity={setSort}
                  sort={sort}
                />
              </>
            ) : (
              <MissingAccess />
            )}
          </>
        )}
      </div>
      <ModalConfirm
        showModal={showConfirmModal}
        title={titleConfirmModal}
        body={bodyConfirmModal}
        onConfirmed={confirmActionWithSelectedItems}
        hideModal={() => setShowConfirmModal(false)}
      />
    </div>
  );
};

export default ActivityList;

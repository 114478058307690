import { Label } from './Labels';
import { ValueFilter } from './Properties';

export interface Announce {
  id: number;
  categoryTitle: string;
  description: string;
  floorNumber: string;
  sources: any[];
  price: string;
  phones: string[];
  streetFrontDistance: string;
  usableSurface: string;
  townTitle: string;
  countyTitle: string;
  checked: boolean;
  dateUpdated: string;
  dateCreated: string;
  landSurface: string;
  media: any[];
  constructionYear: string;
  zoneLabel: string;
  prices: any[];
  currencySymbol: string;
  comfort: string;
  floors: string;
  numberOfRooms: string;
  labels: Label[];
}

export interface Filters {
  offset?: number;
  limit?: number;
  category?: number;
  filters?: any[];
}

export interface Filter {
  id: number;
  title: string;
  propertyTypeView: string;
  propertyTypeAdd: string;
  propertyShowOnAdd: number;
  propertyShowOnView: number;
  requestShowOnAdd: number;
  requestShowOnView: number;
  requestTypeAdd: string;
  requestTypeView: string;
  specificTypeId?: number;
  values: ValueFilter[];
  children?: Filter[];
  adGroup: number;
  propertyGroup: number;
  requestGroup: number;
}

export interface LabelsParams {
  announcementId: number;
  labelsIds: number[];
}

export const hasValue = (field: any) => {
  return field && field !== '' && field !== '-' && field !== 'nespecificat';
};

import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import ContactUserInfo from '../../components/ContactUserInfo/ContactUserInfo';
import ContactsModal from '../../components/ContactsModal';
import FormSelect from '../../components/FormSelect';
import MultiselectCheckboxes from '../../components/MultiselectCheckboxes';
import TagList from '../../components/TagList/TagList';
import { clearTags } from '../../redux/contacts/contacts-slice';
import {
  getContacts,
  getContactsTags,
} from '../../redux/contacts/contactsThunk';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import useDebounce from '../../helpers/useDebounce';
import { parseValues } from '../../helpers/useHelper';
import { ContactsType, SelectValue } from '../../models/Properties';
import './AddContactsForm.scss';

interface Props {
  formData: any;
  setFormData: (form: any) => void;
}

const AddContactsForm: React.FC<Props> = ({ formData, setFormData }) => {
  const dispatch = useAppDispatch();
  const { contacts, contactsTags } = useAppSelector(state => state.contacts);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contactsQuery, setContactsQuery] = useState<string | undefined>(
    undefined,
  );
  const debouncedContactSearch = useDebounce(contactsQuery ?? null, 300);

  useEffect(() => {
    dispatch(getContacts({ q: contactsQuery }));
    // eslint-disable-next-line
  }, [debouncedContactSearch]);

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLSelectElement>,
    id: number,
  ) => {
    if (event.key === 'Enter') {
      setFormData({
        ...formData,
        contacts: formData.contacts?.map((contact: any) => {
          const newValue = (event.target as HTMLSelectElement).value;
          if (id === contact.id) {
            let tagsList =
              formData.contacts?.find(
                (contact: any) => contact.id === Number(id),
              )?.tags ?? [];
            tagsList = [...tagsList, newValue];
            return {
              ...contact,
              tags: tagsList ?? [],
            };
          } else return contact;
        }),
      });
    }
  };

  const addTag = (e: string, id: number) => {
    setFormData({
      ...formData,
      contacts: formData.contacts?.map((contact: any) => {
        if (id === contact.id) {
          let tagsList =
            formData.contacts?.find((contact: any) => contact.id === Number(id))
              ?.tags ?? [];

          if(!tagsList.includes(e)) {
            tagsList = [...tagsList, e];
          }

          return {
            ...contact,
            tags: tagsList ?? [],
          };
        } else return contact;
      }),
    });
  };

  const removeTag = (e: string, id: number) => {
    setFormData({
      ...formData,
      contacts: formData.contacts?.map((contact: any) => {
        if (id === contact.id) {
          let tagsList = formData.contacts?.find(
            (contact: any) => contact.id === id,
          )?.tags;

          return {
            ...contact,
            tags:
              tagsList?.filter((tag: any) => String(tag) !== String(e)) ?? [],
          };
        } else return contact;
      }),
    });
  };

  const removeContact = (id: any) => {
    setFormData({
      ...formData,
      contacts: formData.contacts.filter((contact: any) => contact.id !== id),
    });
  };

  return (
    <div className='flex-container display-flex'>
      <Container>
        <Row className='row-53'>
          <Col xl='12' className='content-block'>
            <h2 className='heading-block'>Contacte</h2>
            <p>
              Adauga contacte personalizate asociate acestei proprietati pentru
              a sti exact pe cine sa contactezi in cazul unei viszionari sau
              pentru mai multe detalii"
            </p>
          </Col>
          <Col lg='6'>
            <div className='add-contact'>
              <div className='form-item'>
                <MultiselectCheckboxes
                  isSearchable={true}
                  onInputChange={(e: string) => {
                    setContactsQuery(e);
                  }}
                  onChange={(e: string[]) => {
                    setFormData({
                      ...formData,
                      contacts: e.map(id => {
                        return {
                          id: Number(id),
                          tags:
                            formData.contacts?.find(
                              (contact: ContactsType) =>
                                contact.id === Number(id),
                            )?.tags ?? [],
                        };
                      }),
                    });
                  }}
                  placeholder={'Cauta...'}
                  labelText={true}
                  inlineLabel={'Adauga contact:'}
                  showValue={false}
                  value={formData.contacts?.map((contact: any) =>
                    String(contact?.id),
                  )}
                  options={parseValues(contacts) ?? []}
                />
              </div>
              <div
                className='form-item-button'
                onClick={() => setIsModalOpen(true)}
              >
                <button className='button-md button-blue'>Adauga</button>
              </div>
            </div>
            <div className='form-item'>
              <div className='form-item-group form-item-group-textarea'>
                <label htmlFor='description' className='form-item-group-label'>
                  Comentarii despre Contacte:
                </label>
                <textarea
                  id='description'
                  className='form-item-control textarea-lg'
                  maxLength={250}
                  placeholder='Maxim 250 de caractere'
                  value={formData.contactsComments}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setFormData({
                      ...formData,
                      contactsComments: e.target.value,
                    })
                  }
                ></textarea>
              </div>
            </div>
          </Col>
          {formData.contacts && formData.contacts?.length > 0 && (
            <Col lg='6'>
              <div className='ads-contacts-block bg-blue'>
                {contacts &&
                  contacts.length > 0 &&
                  contacts?.map((item) => {
                    if (
                      formData.contacts?.find(
                        (contact: any) => contact.id === item.id,
                      )
                    )
                      return (
                        <div
                          className='bg-white ads-contact-item'
                          key={item.id}
                        >
                          <ContactUserInfo
                            item={item}
                            removeContact={removeContact}
                          />
                          <div className='ads-contact-item__bottom'>
                            <div className='form-item'>
                              <FormSelect
                                type='text'
                                id='ads-more'
                                isSearchable={true}
                                options={parseValues(contactsTags)}
                                onInputChange={(e: string) => {
                                  e.length > 0 && dispatch(getContactsTags(e));
                                }}
                                onChange={(e: SelectValue) => {
                                  addTag(e.label, item.id);
                                }}
                                handleKeyDown={(
                                  e: React.KeyboardEvent<HTMLSelectElement>,
                                ) => handleKeyDown(e, item.id)}
                                onMenuClose={() => dispatch(clearTags())}
                              />
                            </div>
                            <TagList
                              removeTag={e => removeTag(e, item.id)}
                              tags={
                                formData.contacts?.find(
                                  (contact: any) => contact.id === item.id,
                                )?.tags ?? []
                              }
                            />
                          </div>
                        </div>
                      );
                    else return null;
                  })}
              </div>
            </Col>
          )}
        </Row>
        <ContactsModal
          isModalOpen={isModalOpen}
          closeModal={() => setIsModalOpen(false)}
        />
      </Container>
    </div>
  );
};

export default AddContactsForm;
